import {serializable, alias, primitive, list, object} from 'serializr';

export class User {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('email', primitive()))
    email?: string;
    @serializable(alias('uid', primitive()))
    uid?: string;
    @serializable(alias('first_name', primitive()))
    firstName?: string;
    @serializable(alias('last_name', primitive()))
    lastName?: string;
    @serializable(alias('username', primitive()))
    username?: string;
}

export class UserListModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('bdd_id', primitive()))
    bddId?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('invite_id', primitive()))
    inviteId?: string;
    @serializable(alias('number_of_plans', primitive()))
    numberOfPlans?: string;
    @serializable(alias('pro_enabled', primitive()))
    proEnabled?: string;
}

export class RidePreferenceModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('user_id', primitive()))
    userId?: string;
    @serializable(alias('ride_type_id', primitive()))
    rideTypeId?: string;
    @serializable(alias('ride_type_name', primitive()))
    rideTypeName?: string;
}

export class CuisinePreferenceModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('user_id', primitive()))
    userId?: string;
    @serializable(alias('cuisine_id', primitive()))
    cuisineId?: string;
    @serializable(alias('cuisine_name', primitive()))
    cuisineName?: string;
}

export class MobilityPreferenceModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('user_id', primitive()))
    userId?: string;
    @serializable(alias('mobility_type_id', primitive()))
    mobilityTypeId?: string;
    @serializable(alias('mobility_type_name', primitive()))
    mobilityTypeName?: string;
}

export class UserDetailsModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('bdd_id', primitive()))
    bddId?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('height', primitive()))
    height?: string;
    @serializable(alias('ride_preferences', list(object(RidePreferenceModel))))
    ridePreferences?: string;
    @serializable(alias('cuisine_preferences', list(object(CuisinePreferenceModel))))
    cuisinePreferences?: string;
    @serializable(alias('mobility_preferences', list(object(MobilityPreferenceModel))))
    mobilityPreferences?: string;
    @serializable(alias('invite_id', primitive()))
    inviteId?: string;
    @serializable(alias('number_of_plans', primitive()))
    numberOfPlans?: string;
    @serializable(alias('number_of_groups', primitive()))
    numberOfGroups?: string;
    @serializable(alias('pro_enabled', primitive()))
    proEnabled?: string;
}
