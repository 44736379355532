import axiosInstance from "../../interceptor/axiosInstance";
import {HIDDEN_MICKEYS, RIDDLE_COUNT, TRIVIA_COUNT} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {HiddenMickeysModel} from "../../models/games.model";

export default class GamesService {

    //get hidden mickeys
    static fetchHiddenMickeys(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ){
        return axiosInstance
            .get(HIDDEN_MICKEYS)
            .then(response => {
                const hiddenMickeys = deserialize(HiddenMickeysModel,response.data['hidden_mickeys'])
                onSuccess(hiddenMickeys);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch hidden mickeys",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //get hidden mickey details
    static fetchHiddenMickeyDetails(
        hiddenMickeyId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ){
        return axiosInstance
            .get(HIDDEN_MICKEYS+`/${hiddenMickeyId}`)
            .then(response => {
                const hiddenMickey = deserialize(HiddenMickeysModel,response.data['hidden_mickey'])
                onSuccess(hiddenMickey);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch hidden mickey data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //create hidden mickey
    static createHiddenMickey(
        data:object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ){
        return axiosInstance
            .post(HIDDEN_MICKEYS,data)
            .then(() => {
                onSuccess();
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to create the hidden mickey",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //update hidden mickey
    static updateHiddenMickey(
        hiddenMickeyId:string,
        data:object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ){
        return axiosInstance
            .put(HIDDEN_MICKEYS+`/${hiddenMickeyId}`,data)
            .then(() => {
                onSuccess();
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to update the hidden mickey",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete hidden mickey
    static deleteHiddenMickey(
        hiddenMickeyId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ){
        return axiosInstance
            .delete(HIDDEN_MICKEYS+`/${hiddenMickeyId}`)
            .then(() => {
                onSuccess();
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to delete the hidden mickey",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //fetch trivia count
    static fetchTriviaCount(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ){
        return axiosInstance
            .get(TRIVIA_COUNT)
            .then(response => {
                onSuccess(response.data['trivia_count']);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch trivia count",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //fetch jokes count
    static fetchJokesCount(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ){
        return axiosInstance
            .get(RIDDLE_COUNT)
            .then(response => {
                onSuccess(response.data['riddle_count']);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch jokes count",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete hidden mickey
    static deleteHiddenMickeyImage(
        hiddenMickeyId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ){
        return axiosInstance
            .delete(HIDDEN_MICKEYS+`/${hiddenMickeyId}/delete_image`)
            .then(() => {
                onSuccess();
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to delete the attachment",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
