import React, {useState} from "react";
import {DeleteConfirmation} from "../DeleteConfirmation";
import "./imageViewer.scss"

const ImageViewer = (props:any) => {

    const {imageUrl,deleteService, refreshData, id} = props;

    const [showDelete, setShowDelete] = useState<boolean>(false);

    const handleAttachmentDelete = () => {
        deleteService(id,
            ()=>{
                setShowDelete(false)
                setTimeout(()=>refreshData(),200)
            },
            ()=>{},
            ()=>{},
        )
    }

    return(
        <>
            <DeleteConfirmation deleteVisible={showDelete}
                                setDeleteVisible={setShowDelete}
                                text='attachment'
                                handleDelete={handleAttachmentDelete}
            />
            {imageUrl && <div className='image-container'>
                <span className='image-delete-icon' onClick={()=>setShowDelete(true)}>x</span>
                <img className="banner-thumbnail"  src={imageUrl} alt=''/>
            </div>}
        </>
    )
}

export default ImageViewer
