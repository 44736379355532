import React from "react";
import { Table } from "antd";
import './rideResDetails.scss';

const ApprovalView = (props: { dataSource: any; columns: any; rowSelection: any; })=>{
    const {dataSource,columns,rowSelection} = props;

    return(
        <Table className="details-table" dataSource={dataSource} columns={columns} rowSelection={rowSelection} pagination={false}/>
    )
}

export default ApprovalView;
