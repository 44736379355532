import {serializable, alias, primitive, list} from 'serializr';

export class ExtraMagicHoursModel {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    parkLocation?: string;
    @serializable(alias('date', primitive()))
    date?: string;
    @serializable(alias('morning_hours', primitive()))
    morningHours?: string;
    @serializable(alias('evening_hours', primitive()))
    eveningHours?: string;
}

export class LocationEventDatesModel {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('christmas_dates', list(primitive())))
    christmasDates?: string;
    @serializable(alias('halloween_dates', list(primitive())))
    halloweenDates?: string;
}
