import {Form, Formik} from "formik";
import {validationSchema} from "./validation";
import InputField from "../../../shared/components/InputField";
import {Button} from "antd";
import React, {useState} from "react";
import Notification from "../../../shared/components/Notification";
import {NotificationTypes} from "../../../enums/notificationTypes";
import UserService from "../../../services/AuthService/auth.service";
import {withRouter} from "react-router-dom";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";

const ResetPassword = (props:any)=>{

    const resetInitialValue = {
        password: "",
        otp: "",
    }

    const [loading,setLoading] = useState<boolean>(false)

    const onResetSubmit = async(values:any)=>{
        if(values.password !== values.confirmPassword){
            Notification({ message: "Invalid request", description: "Passwords do not match",type: NotificationTypes.ERROR });
        }else {
            setLoading(true)
            let data = {user: values};
            data.user.email = props.location.state.email;
            delete data.user.confirmPassword;
            await UserService.resetPassword(data,
                ()=>{
                    setLoading(false)
                    props.history.push(appRoutes.LOGIN)
                },
                ()=>{setLoading(false)},
                ()=>{},
            )
        }
    };

    return(
        <div className="login-form">
            <div className="login-form__title">Enter the OTP and password</div>
            <Formik
                initialValues={resetInitialValue}
                onSubmit={onResetSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <div className='login-form__field'>
                        <div className="label">OTP</div>
                        <InputField type="text" name="otp" placeholder="Enter OTP" />
                    </div>
                    <div className='login-form__field'>
                        <div className="label">Password</div>
                        <InputField type="password" name="password" placeholder="Enter password" />
                    </div>
                    <div className='login-form__field'>
                        <div className="label">Confirm Password</div>
                        <InputField type="password" name="confirmPassword" placeholder="Enter password again" />
                    </div>
                    <Button htmlType="submit" className="login-form__submit" loading={loading}>Submit</Button>
                </Form>
            </Formik>
        </div>
    )
}

export default withRouter(ResetPassword);
