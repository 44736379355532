import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from 'antd';
import Error from "../Error";

const {TextArea} = Input;

interface InputFieldProps {
    type: string;
    name: string;
    placeholder: string;
    maxLength?:number;
}

const InputField: FC<InputFieldProps> = (props) => {
    const { name,type } = props;
    return (
        <div>
            {type === 'textarea' ? <Field as={TextArea} rows={2} {...props}/> : <Field as={Input} {...props}/>}
            <ErrorMessage name={name}>
                {(message: string) => <Error message={message} />}
            </ErrorMessage>
        </div>
    )
}

export default InputField;
