import axiosInstance from "../interceptor/axiosInstance";
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";
import * as apiRoutes from "../routes/routeConstants/apiRoutes";
import {generatePath} from "react-router-dom";

export default class AttachmentService {

    //delete attachment
    static deleteAttachment(
        id:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(generatePath(apiRoutes.ATTACHMENTS_DELETE,{id: id}))
            .then(() => {
                onSuccess();
                Notification({
                    message: "Deleted",
                    description: "The attachment has been deleted successfully",
                    type: NotificationTypes.SUCCESS
                });
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to delete the attachment",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
