import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
    otp: Yup.string()
        .min(6, 'OTP has to be 6 characters!')
        .required('OTP is required!'),
    password: Yup.string()
        .min(6, 'Password has to be longer than 6 characters!')
        .required('Password is required!')
});
