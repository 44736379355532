import { AUTHENTICATED, UNAUTHENTICATED } from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";

interface IAuthState {
    authenticated: boolean;
    user?: string | null;
}

const initState: IAuthState = {
    authenticated: !!localStorage.getItem('user'),
    user: null
};

const authReducer = CreateReducer(initState, {
    [AUTHENTICATED](state: any, action: any) {
        const { authenticated, user } = action.payload;
        return {
            ...state,
            authenticated: authenticated,
            user: user
        };
    },
    [UNAUTHENTICATED](state: any, payload: any) {
        const { authenticated } = payload;
        return { ...state, authenticated: authenticated };
    }
});

export default authReducer;



