import axiosInstance from "../../interceptor/axiosInstance";
import {FETCH_CROWD_CALENDAR, REFRESH_CROWD_CALENDAR} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {CrowdCalendarList} from "../../models/crowdCalendar.model";

export default class CrowdCalendarService {

    //get crowd calendar
    static fetchCrowdCalendar(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(FETCH_CROWD_CALENDAR)
            .then(response => {
                const crowdCalendarList = deserialize(CrowdCalendarList, response.data["crowd_calendars"]);
                const lastUpdatedTime = response.data["last_updated_time"];
                onSuccess(crowdCalendarList,lastUpdatedTime);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //refresh crowd calendar
    static refreshCrowdCalendar(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(REFRESH_CROWD_CALENDAR)
            .then(response => {
                onSuccess(response);
                Notification({
                    message: "Success",
                    description: "Refresh process has been started successfully",
                    type: NotificationTypes.SUCCESS
                });
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to trigger the refresh process",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
