import React from "react";
import AuthRouter from "../authRouters";
import loginImage from "../../../assets/bdd-login.png";


const AuthWrapper = () => {
    return (
        <>
            <div className="login-image">
                <img src={loginImage} alt=''/>
            </div>
            <AuthRouter />
        </>
    )
}

export default AuthWrapper;
