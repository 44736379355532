import React from "react";
import './showTags.scss';

const ShowTags = (props: any) =>{
    const {title,tags} = props;

    return(
        <div>
            <div className="label">{title}</div>
            {tags.map((tag: React.ReactNode)=>{
                return(
                    <div className="tag-value">{tag}</div>
                )
            })}
        </div>
    )
}

export default ShowTags;
