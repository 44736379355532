import MetaService from "../../services/meta.service";

export const fetchMetaValues = (metaName: string, setState: Function) => {
    MetaService.fetchMetaValues(metaName,
        (response: any)=>{
            constructMetaValues(response,setState)
        },
        ()=>{},
        ()=>{}
    ).then()
}

export const constructMetaValues = (data:any,setState:Function) => {
    setState(
        data.map((obj: { name: any; id: any; key:any }) => {
            return {
                value:obj?.id?.toString() || obj?.key?.toString(),
                label:obj.name
            }
        })
    );
}
