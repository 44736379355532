import React, {useEffect, useState} from "react";
import {Button, Modal, Skeleton} from "antd";
import '../blogs.scss';
import {Field, Form, Formik} from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import FileUpload from "../../../shared/components/FileUpload";
import {serialize} from "serializr";
import {convertJSONToFormData} from "../../../shared/utils/jsonToFormdata";
import {BlogsModel} from "../../../models/blogs.model";
import BlogsService from "../../../services/BlogsService/blogs.service";
import * as apiRoutes from "../../../routes/routeConstants/apiRoutes";
// @ts-ignore
import {CKEditor} from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "ckeditor5-build-classic-plus";
import {getHeaders} from "../../../interceptor/axiosInstance";
import ImageViewer from "../../../shared/components/ImageViewer";

const BlogsForm = (props:any) => {

    const {visible,setVisible, blogId,refreshData} = props;

    const [initialValues,setInitialValues] = useState<any>();
    const [blog,setBlog] = useState<any>();
    const [fileList,setFileList] = useState<any>([]);
    const [buttonLoading,setButtonLoading] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        if(blogId && blogId !== 'new') {
            fetchBlogDetails()
        }else{
            setFormValues({})
            setBlog('')
        }
    },[blogId]);

    const fetchBlogDetails = () => {
        setLoading(true)
        BlogsService.fetchBlogDetails(blogId,
            (blog: any) => {
                setBlog(blog)
                setFormValues(blog)
            },
            () => {
            },
            () => {
                setLoading(false)
            },
        ).then()
    }

    const setFormValues = (blog:any) => {
        setInitialValues(
            {
                title:blog?.title || '',
                tags:blog?.tags,
                author:blog?.author || '',
                status:blog?.status || 'Published',
                content:blog?.content || '',
                previewText:blog?.previewText || '',
            }
        )
    }

    const onSubmit = (blog:any) => {
        setButtonLoading(true)
        let data = {blog: serialize(BlogsModel,blog)};

        if(fileList.length > 0){
            data.blog.image = fileList.map((element:any) => element.originFileObj)[0]
        }

        let formData = convertJSONToFormData(data)

        if(blogId !== 'new') {
            BlogsService.updateBlog(formData,blogId,
                () => {
                    handleSuccess()
                },
                () => {},
                () => {setButtonLoading(false)},
            ).then()
        }else{
            BlogsService.createBlog(formData,
                () => {
                    handleSuccess()
                },
                () => {},
                () => {setButtonLoading(false)},
            ).then()
        }
    }

    const handleSuccess = () => {
        setFormValues({})
        setVisible(false)
        setBlog('')
        refreshData('')
        setFileList([])
    }

    const tagsMeta = [
        {label:'Create a Character',value:'Create a Character'},
        {label:'Design Your Dream Castle',value:'Design Your Dream Castle'},
        {label:'Ride Designer',value:'Ride Designer'},
    ]

    const statusMeta = [
        {label:'Publish',value:'Published'},
        {label:'Unpublish',value:'Unpublished'},
    ]

    let headers = getHeaders();
    delete headers['Content-Type']

    return(
        <Modal title={null}
               centered
               onCancel={()=>setVisible(false)}
               visible={visible}
               footer={null}
               className='blogs__modal'
        >
            <div className='blogs__title'>Blog</div>
            {loading && <Skeleton active/>}
            {!loading && <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                {({
                    setFieldValue,
                  }) => {
                    return (
                        <Form>
                            <div className='blogs__form-field'>
                                <div className='label'>Title</div>
                                <InputField type="text" name="title" placeholder="Blog Title"/>
                            </div>
                            <div className='blogs__form-field'>
                                <div className='label'>Tags</div>
                                <DropdownField mode='multiple' name='tags'
                                               onChange={(value: any) => {
                                                   setFieldValue('tags',value)
                                               }}
                                               options={tagsMeta} placeholder='Select Tags'
                                />
                            </div>
                            <div className='blogs__form-field'>
                                <div className='label'>Author Name</div>
                                <InputField type="text" name="author" placeholder="Author Name"/>
                            </div>
                            <div className='blogs__form-field'>
                                <div className='label'>Preview text</div>
                                <InputField type="text"
                                            name="previewText"
                                            placeholder="preview text"
                                            maxLength={100}
                                />
                            </div>

                            <div className='blogs__form-field'>
                                <div className='label'>Status</div>
                                <DropdownField mode='single' name='status'
                                               onChange={(value: any) => {
                                                   setFieldValue('status',value)
                                               }}
                                               options={statusMeta} placeholder='Select Status'
                                />
                            </div>

                            <div className='blogs__form-field'>
                                <div className='label'>Banner Image</div>
                                <FileUpload fileList={fileList} setFileList={setFileList} limit={1}/>
                                {fileList.length === 0 &&
                                    <ImageViewer imageUrl={blog?.imageThumbUrl}
                                                 deleteService={BlogsService.deleteBlogImage}
                                                 id={blog?.id}
                                                 refreshData={fetchBlogDetails}
                                    />
                                }
                            </div>

                            <div className='blogs__editor'>
                                <Field name="content">
                                    {() => (
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={{
                                                simpleUpload:{
                                                    uploadUrl:apiRoutes.BASE_URL+apiRoutes.UPLOAD_IMAGE,
                                                    headers: headers
                                                }
                                            }}
                                            data={blog?.content || ''}
                                            onChange={ ( event:any, editor:any ) => {
                                                const data = editor.getData();
                                                setFieldValue("content",data)
                                                //console.log( { event, editor, data } );
                                            } }
                                            onFileUploadRequest
                                        />
                                    )}
                                </Field>
                            </div>

                            <div className="form-actions">
                                <Button key="back" type="text" onClick={()=>setVisible(false)}>Cancel</Button>
                                <Button key="approve" type="primary" htmlType="submit" loading={buttonLoading}
                                        className="hidden-mickey__form-submit">Save</Button>
                            </div>
                        </Form>
                    )
                }
                }
            </Formik>}
        </Modal>
    )
}

export default BlogsForm;
