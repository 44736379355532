import React, {useEffect, useState} from "react";
import './rideDetail.scss';
import {LeftSquareFilled} from "@ant-design/icons/lib";
import ShowDetails from "../../../shared/components/ShowDetails";
import photoPassPlaceholder from "../../../assets/photo-pass-placeholder.png";
import ridePlaceholder from "../../../assets/ride-placeholder.jpg";
import ShowTags from "../../../shared/components/ShowTags";
import {withRouter} from "react-router";
import RideService from "../../../services/RideService/ride.service";
import moment from "moment";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import CircularLoader from "../../../shared/components/CircularLoader";
import RideImages from "../RideImages";
import AttractionImages from "../RideImages";

const RideDetails = (props: any) => {

    let {rideId}  = props.match.params;

    const [rideValues, setRideValues] = useState<any>();
    const [loading,setLoading] = useState<boolean>(false);
    const [themeParksRideMeta, setThemeParksRideMeta] = useState<any>([]);

    useEffect(()=>{
        fetchRideDetails();
    },[rideId]);

    const fetchRideDetails = () => {
        setLoading(true)
        RideService.fetchRideDetails(rideId,
            (rideValues: any) => {
                setRideValues(rideValues);
                RideService.fetchThemeParkRides(rideValues.parkId,
                    (response:any)=>{
                        setThemeParksRideMeta(
                            response.map((obj: { name: any; id: any; }) => {
                                return {
                                    value:obj.id.toString(),
                                    label:obj.name
                                }
                            })
                        )
                    },
                    ()=>{},
                    ()=>{},
                ).then()
            },
            () => {
            },
            () => {
                setLoading(false)
            }
        ).then()
    }

    const rideDetails=[
        {label:'Ride ID', value:rideId},
        {label:'Ride name', value:rideValues?.name},
        {label:'Short name', value:rideValues?.shortName},
        {label:'Updated at', value: moment(rideValues?.updatedTime).format('DD/MM/YYYY')},
        {label:'Morning magic hours', value:rideValues?.openEmhMorning ? 'Yes' : 'No'},
        {label:'Evening magic hours', value:rideValues?.openEmhEvening ? 'Yes' : 'No'},
        {label:'Single rider', value: rideValues?.singleRider ? 'Yes' : 'No'},
        {label:'Time zone', value:rideValues?.timeZone},
        {label:'Very Merry Christmas Party', value:rideValues?.openVeryMerry ? 'Yes' : 'No'},
        {label:'Scary halloween', value: rideValues?.openNotSoScary ? 'Yes' : 'No'},
        {label:'Ride duration', value:rideValues?.duration+'mins'},
        {label:'Average Wait time', value:rideValues?.averageWaitTime? rideValues.averageWaitTime+'mins' : '-'},
        {label:'Height restriction', value:rideValues?.heightRestriction? rideValues.heightRestriction+'inches' : '-'},
        {label: 'Description', className:'show-textarea', value:rideValues?.whatItIs},
        {label:'Accessibility info filter', value:'NA'},
        {label:'Rider swap', value:rideValues?.riderSwap ? 'Yes' : 'No'},
        {label:'Crowd expectation', value:'NA'},
        {label:'Fast pass', value:rideValues?.fastpassOnly ? 'Yes' : 'No'},
        {label:'Best time to go', value:rideValues?.whenToGo},
        {label:'Operator type', value:rideValues?.operatorType},
        {label:'Indoor ride', value:'NA'},
        {label:'Wet ride', value:rideValues?.wet ? 'Yes' : 'No'},
        {label:'Hidden mickey associated with ride', value: rideValues?.hiddenMickeyAssociated ? 'Yes' : 'No'},
        {label:'Ride status', value:rideValues?.status},
        {label:'Photo pass available', value: rideValues?.isPhotopassAvailable ? 'Yes' : 'No'},
 ];

    const rideType = rideValues?.rideTypeMaps?.map((el:any) => el.rideTypeName) || [];

    const themeType = rideValues?.rideThemeMaps?.map((el:any) => el.themeName) || [];

    return(
        <div className="component-styles">
            <div className="back-button" onClick={()=>props.history.push(appRoutes.RIDES)}><LeftSquareFilled />Back</div>
            {loading && <CircularLoader/>}
            {!loading && <div className="ride-details">
                <ShowDetails details={rideDetails}/>
                {rideValues?.isPhotopassAvailable &&
                <>
                    <div className='photo-pass-description'>
                        <div className='label'>Photo pass description</div>
                        <div className='value'>{rideValues?.photopassDescription || 'NA'}</div>
                    </div>
                    <div className="photo-pass">
                        <div className="label">Photo pass</div>
                        {rideValues?.photopassThumbUrl &&
                        <img src={rideValues?.photopassThumbUrl} alt='photopass'/>}
                    </div>
                </>}
                <AttractionImages attractionValues={rideValues}
                                  labelText={'Photos for ride'}
                                  refreshAttraction={fetchRideDetails}
                />
                <ShowTags title="Ride type" tags={rideType}/>
                <ShowTags title="Theme" tags={themeType}/>
                <div className='ride-location-details'>
                    <div className='ride-location-details__title'>Associate this ride with Themepark API.</div>
                    <div className="ride-location-details__field">
                        <div className='label'>Ride</div>
                        <div className='value'>
                            {themeParksRideMeta?.filter((meta:any) =>
                                meta?.value === rideValues?.cubeHouseId)[0]?.label || '-'}
                        </div>
                        <div className='ride-id'>Ride ID: {rideValues?.cubeHouseId}</div>
                    </div>
                    <div className="ride-location-details__field">
                        <div className='label'>Geo Coordinates (lat,long)</div>
                        <div className='value'>{rideValues?.lat},{rideValues?.long}</div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default withRouter(RideDetails);
