import {alias, primitive, serializable} from "serializr";

export class HiddenMickeysModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('id', primitive()))
    key?: string;
    @serializable(alias('park_id', primitive()))
    parkId?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    parkLocation?: string;
    @serializable(alias('ride_id', primitive()))
    rideId?: string;
    @serializable(alias('ride_name', primitive()))
    rideName?: string;
    @serializable(alias('lat', primitive()))
    lat?: string;
    @serializable(alias('long', primitive()))
    long?: string;
    @serializable(alias('desc', primitive()))
    description?: string;
    @serializable(alias('image_url', primitive()))
    imageUrl?: string;
    @serializable(alias('image_thumb_url', primitive()))
    imageThumbUrl?: string;
}
