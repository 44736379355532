import axiosInstance from "../../interceptor/axiosInstance";
import {
    DELETE_PENDING_RIDE,
    LIVE_RIDES_LIST,
    PENDING_RIDES_LIST,
    RIDE_DETAILS,
    RIDES_COMPARE, THEMEPARK_API,
} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {RideDetailsModel, RideList} from "../../models/ride.model";
import {generatePath} from "react-router-dom";

export default class RideService {

    //get pending ride list
    static fetchPendingRidesList(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(PENDING_RIDES_LIST)
            .then(response => {
                const rideList =  deserialize(RideList,response.data["external_api_rides"]);
                onSuccess(rideList);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to fetch the data",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //get ride list
    static fetchLiveRidesList(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(LIVE_RIDES_LIST + '?status=all')
            .then(response => {
                const rideList =  deserialize(RideList,response.data["rides"]);
                onSuccess(rideList);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to fetch the data",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //get data for comparison
    static fetchRidesCompare(
        rideId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(generatePath(RIDES_COMPARE,{id:rideId}))
            .then(response => {
                const ridesData = response.data['compare_data']
                onSuccess(ridesData);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to fetch the data",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //get ride details
    static fetchRideDetails(
        rideId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(generatePath(RIDE_DETAILS,{id:rideId}))
            .then(response => {
                const rideDetails = deserialize(RideDetailsModel,response.data['ride']);
                onSuccess(rideDetails);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to fetch the details",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete a pending ride
    static deletePendingRide(
        rideId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(generatePath(DELETE_PENDING_RIDE,{id:rideId}))
            .then(response => {
                Notification({ message: "Deleted", description: "Ride has been deleted successfully", type: NotificationTypes.SUCCESS });
                onSuccess(response);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to delete",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //update live ride
    static updateLiveRide(
        rideId: string,
        data: object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(generatePath(RIDE_DETAILS,{id:rideId}),data)
            .then(response => {
                Notification({ message: "Update", description: "Updated successfully", type: NotificationTypes.SUCCESS });
                onSuccess(response);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to update",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //create new ride
    static createNewRide(
        data: object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(LIVE_RIDES_LIST,data)
            .then(response => {
                Notification({ message: "Ride submission", description: "Completed successfully", type: NotificationTypes.SUCCESS });
                onSuccess(response['data']);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to post the data",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //get theme park ride options for mapping
    static fetchThemeParkRides(
        parkId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(THEMEPARK_API+`?park_id=${parkId}`)
            .then(response => {
                const rideList =  response.data["cubehouse_rides"]
                onSuccess(rideList);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to fetch theme park ride data",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete ride photopass
    static deleteRidePhotopass(
        rideId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(LIVE_RIDES_LIST+`/${rideId}/delete_photo_pass`)
            .then(response => {
                onSuccess(response);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to delete the attachment",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }
}
