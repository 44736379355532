import React from "react";
import { Menu } from 'antd';
import "./sidebar.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import avatarPlaceHolder from "../../../assets/avatar_placeholder.png";
import appLogo from "../../../assets/bdd-logo.png"
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import '../../../assets/app-icons/style.css';
import {withRouter} from "react-router";
import UserService from "../../../services/AuthService/auth.service";

const SideBar = (props: any) => {

  const handleLogout = async() => {
      await UserService.logoutUser(
          (res: any) => {
              props.setUnAuthenticated();
              props.history.push(appRoutes.AUTH)
          }, (error: Error) => {
              console.log(error.message);
          }, () => {
          });

  };

  return (
      !window.location.pathname.includes('/auth') &&
      <div className="side-nav-bar">
          <div className="app-logo">
              <img src={appLogo} alt={'user'}/>
          </div>
          <Menu onClick={()=>{props.history.push(appRoutes.DASHBOARD)}}>
                <Menu.Item className="navbar-item">
                  <div className="icon-dashboard icon"/>
                  <div className="menu-title">Dashboard</div>
                </Menu.Item>
          </Menu>
          <Menu onClick={()=>{props.history.push(appRoutes.RIDES)}}>
                <Menu.Item className="navbar-item">
                    <div className="icon-rides icon"/>
                    <div className="menu-title">Rides</div>
                </Menu.Item>
          </Menu>
          <Menu onClick={()=>{props.history.push(appRoutes.RESTAURANTS)}}>
                <Menu.Item className="navbar-item">
                    <div className="icon-resturants icon"/>
                    <div className="menu-title">Restaurants</div>
                </Menu.Item>
          </Menu>
          <Menu onClick={()=>{props.history.push(appRoutes.USERS)}}>
                <Menu.Item className="navbar-item">
                    <div className="icon-users icon"/>
                    <div className="menu-title">Users</div>
                </Menu.Item>
          </Menu>
          {/* <Menu onClick={()=>{props.history.push(appRoutes.CROWD_CALENDAR)}}>
                <Menu.Item className="navbar-item">
                    <div className="icon-crowd-calendar icon"/>
                    <div className="menu-title">Crowd calendar</div>
                </Menu.Item>
          </Menu> */}
          <Menu onClick={()=>{props.history.push(appRoutes.EXTRA_MAGIC_HOURS)}}>
                <Menu.Item className="navbar-item">
                    <div className="icon-extra-magic-hours icon"/>
                    <div className="menu-title">Extra magic hours</div>
                </Menu.Item>
          </Menu>
          <Menu onClick={()=>{props.history.push(appRoutes.RAIN_GEAR_SHOPS)}}>
                <Menu.Item className="navbar-item">
                    <div className="icon-rain-gear-shops icon"/>
                    <div className="menu-title">Rain gear shops</div>
                </Menu.Item>
          </Menu>
          <Menu onClick={()=>{props.history.push(appRoutes.BLOGS)}}>
                <Menu.Item className="navbar-item">
                    <div className="icon-blogs icon"/>
                    <div className="menu-title">Blogs</div>
                </Menu.Item>
          </Menu>
          <Menu onClick={()=>{props.history.push(appRoutes.GAMES)}}>
                <Menu.Item className="navbar-item">
                    <div className="icon-games icon"/>
                    <div className="menu-title">Games</div>
                </Menu.Item>
          </Menu>
          <div className="user-details">
              <img src={avatarPlaceHolder} alt={'user'}/>
              <div className="username">Adam Bellow</div>
          </div>
          <Menu onClick={handleLogout}>
              <Menu.Item className="navbar-item" key="alipay">
                  <div className="icon-sign-out icon"/>
                  <div className="menu-title">Sign out</div>
              </Menu.Item>
          </Menu>
      </div>
  );
}

export default withRouter(AuthContainer(SideBar));
