import {serializable, alias, primitive} from 'serializr';

export class DashboardModel {
    @serializable(alias('user_count', primitive()))
    userCount?: string;
    @serializable(alias('paid_user_count', primitive()))
    paidUserCount?: string;
    @serializable(alias('total_revenue', primitive()))
    totalRevenue?: string;
    @serializable(alias('park_count', primitive()))
    parkCount?: string;
    @serializable(alias('ride_count', primitive()))
    rideCount?: string;
    @serializable(alias('restaurant_count', primitive()))
    restaurantCount?: string;
    @serializable(alias('group_count', primitive()))
    groupCount?: string;
    @serializable(alias('plan_count', primitive()))
    planCount?: string;
    @serializable(alias('hidden_mickey_count', primitive()))
    hiddenMickeyCount?: string;
    @serializable(alias('riddle_count', primitive()))
    riddleCount?: string;
    @serializable(alias('trivium_count', primitive()))
    triviumCount?: string;
    @serializable(alias('blog_count', primitive()))
    blogCount?: string;
}
