import React, {useEffect, useState} from "react";
import './crowdCalendar.scss';
import TabsTable from "../../shared/components/TabsTable";
import {Button} from "antd";
import CrowdCalendarService from "../../services/CrowdCalendarService/crowdCalendar.service";
import moment from 'moment';

const CrowdCalendar = (props: any) => {

    useEffect(()=>{
        setTableLoading(true)
        CrowdCalendarService.fetchCrowdCalendar(
            (data: any, lastUpdatedTime:any)=>{
                setCalendarData(data);
                setRefreshedAt(lastUpdatedTime)
            },
            ()=>{},
            ()=>{
                setTableLoading(false)
            },
        ).then()
    },[]);

    const[calendarData,setCalendarData]= useState<any>();
    const[refreshedAt,setRefreshedAt]= useState<any>();
    const[tableLoading,setTableLoading]= useState<boolean>(false);

    const handleFetchTrigger = () => {
            CrowdCalendarService.refreshCrowdCalendar(
                ()=>{},
                ()=>{},
                ()=>{},
            ).then()
    }

    const calendarColumns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Location',
            dataIndex: 'parkLocation',
            key: 'parkLocation',
        },
        {
            title: 'Park Name',
            dataIndex: 'parkName',
            key: 'parkName',
        },
        {
            title: 'Crowd expectation',
            dataIndex: 'expectation',
            key: 'expectation',
            render: (expectation: {} | null | undefined) => (
                <div>
                    {expectation || 'Unknown'}
                </div>
            ),
        },
        {
            title: 'Opening & closing time',
            dataIndex: 'parkTiming',
            key: 'parkTiming',
            render: (parkTiming: {} | null | undefined) => (
                <div>
                    {parkTiming || 'Unknown'}
                </div>
            ),
        },
    ];

    const tableProps = [
        {
            key:"1",
            tabName:"CROWD CALENDAR",
            dataSource: calendarData,
            columns: calendarColumns,
            loading: tableLoading
        }
    ];


    return (
        <div className="component-styles">
            {refreshedAt && <div>
                <span className='label'>Latest refresh at: </span>
                <span className='value'>{moment(refreshedAt).format('DD/MM/YYYY HH:mm')}</span>
            </div>}
            <Button className="add-new-ride" onClick={()=>{handleFetchTrigger()}}>Fetch data</Button>
            <TabsTable tableProps={tableProps}/>
        </div>
    )
}

export default CrowdCalendar;
