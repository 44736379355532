import React, {useEffect, useState} from "react";
import '../rainGearShops.scss';
import {Modal, Skeleton} from "antd";
import RainGearShopForm from "../RainGearShopForm";
import RainGearShopService from "../../../services/RainGearShopService/rainGearShop.service";

const RainGearShopDetail = (props: any) => {

    const {visible,setVisible,handleClose,action,shopId} = props;

    const [loading,setLoading] = useState<boolean>(false);
    const [shopData,setShopData] = useState<any>('');

    useEffect(()=>{
        if(shopId){
            setLoading(true)
            RainGearShopService.fetchRainGearShopDetails(shopId,
                (shopData: any)=>{
                    if(shopData.parkId){
                        shopData.parkId = shopData.parkId.toString()
                    }
                    setShopData(shopData)
                },
                ()=>{},
                ()=>{
                    setLoading(false)
                },
            ).then();
        }
    },[shopId])

    return (
        <div className="component-styles">
            <Modal title={action === 'view' ? 'Rain gear shop' : 'New shop'}
                   centered
                   onCancel={()=>handleClose()}
                   visible={visible}
                   footer={null}>
                {loading && <Skeleton active/>}
                {action === 'view' && !loading &&
                    <>
                        <div>
                            <div className='label'>Park location</div>
                            <div className='value'>{shopData?.parkLocation}</div>
                        </div>
                        <div>
                            <div className='label'>Park name</div>
                            <div className='value'>{shopData?.parkName}</div>
                        </div>
                        <div>
                            <div className='label'>Shop name</div>
                            <div className='value'>{shopData?.name}</div>
                        </div>
                        <div>
                            <div className='label'>Geo coordinates(lat,long)</div>
                            <div className='value'>{shopData?.lat+','+shopData?.long}</div>
                        </div>
                        <div>
                            <div className='label'>Purchase items</div>
                            <div className='value'>
                                {shopData?.purchasableItems ?
                                    shopData.purchasableItems.join(',') : '-'}
                            </div>
                        </div>
                        <div>
                            <div className='label'>Shop description</div>
                            <div className='value'>{shopData?.description} </div>
                        </div>
                    </>
                }
                {action === 'edit' && !loading &&
                    <RainGearShopForm setVisible={setVisible}
                                      shopId={shopId}
                                      shopData={shopData}
                                      handleClose={handleClose}/>
                }
            </Modal>
        </div>
    )
}

export default RainGearShopDetail;
