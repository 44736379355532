import {serializable, alias, primitive, list, object} from 'serializr';

export class RideList {
    @serializable(alias('id', primitive()))
    key?: string;
    @serializable(alias('id', primitive()))
    rideId?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('short_name', primitive()))
    shortName?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    parkLocation?: string;
    @serializable(alias('created_at', primitive()))
    lastUpdatedOn?: string;
    @serializable(alias('ride_id', primitive()))
    existingRideId?: string;
    @serializable(alias('park_id', primitive()))
    parkId?: string;
    @serializable(alias('status', primitive()))
    status?: string;
}

class RideThemeModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('ride_id', primitive()))
    rideId?: string;
    @serializable(alias('theme_id', primitive()))
    themeId?: string;
    @serializable(alias('theme_icon_url', primitive()))
    themeIconUrl?: string;
    @serializable(alias('theme_name', primitive()))
    themeName?: string;
}

class RideTypeModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('ride_id', primitive()))
    rideId?: string;
    @serializable(alias('ride_type_id', primitive()))
    rideTypeId?: string;
    @serializable(alias('ride_type_icon_url', primitive()))
    rideTypeIconUrl?: string;
    @serializable(alias('ride_type_name', primitive()))
    rideTypeName?: string;
}

export class AttachmentModel{
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('image_url', primitive()))
    imageUrl?: string;
    @serializable(alias('image_thumb_url', primitive()))
    imageThumbUrl?: string;
}

export class RideDetailsModel {
    @serializable(alias('park_id', primitive()))
    parkId?: string;
    @serializable(alias('name', primitive()))
    name?: string;
    @serializable(alias('short_name', primitive()))
    shortName?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    parkLocation?: string;
    @serializable(alias('open_emh_morning', primitive()))
    openEmhMorning?: boolean;
    @serializable(alias('open_emh_evening', primitive()))
    openEmhEvening?: boolean;
    @serializable(alias('single_rider', primitive()))
    singleRider?: boolean;
    @serializable(alias('time_zone', primitive()))
    timeZone?: string;
    @serializable(alias('open_very_merry', primitive()))
    openVeryMerry?: boolean;
    @serializable(alias('open_not_so_scary', primitive()))
    openNotSoScary?: boolean;
    @serializable(alias('category_code', primitive()))
    categoryCode?: string;
    @serializable(alias('duration', primitive()))
    duration?: string;
    @serializable(alias('what_it_is', primitive()))
    whatItIs?: string;
    @serializable(alias('when_to_go', primitive()))
    whenToGo?: string;
    @serializable(alias('probable_wait_time', primitive()))
    probableWaitTime?: string;
    @serializable(alias('height_restriction', primitive()))
    heightRestriction?: number;
    @serializable(alias('extinct_on', primitive()))
    extinctOn?: string;
    @serializable(alias('opened_on', primitive()))
    openedOn?: string;
    @serializable(alias('wheelchair_transfer_code', primitive()))
    wheelchairTransferCode?: string;
    @serializable(alias('sign_language', primitive()))
    signLanguage?: boolean;
    @serializable(alias('rider_swap', primitive()))
    riderSwap?: boolean;
    @serializable(alias('crowd_calendar_version', primitive()))
    crowdCalendarVersion?: string;
    @serializable(alias('fastpass_only', primitive()))
    fastpassOnly?: boolean;
    @serializable(alias('arrive_before', primitive()))
    arriveBefore?: string;
    @serializable(alias('operator_type', primitive()))
    operatorType?: string;
    @serializable(alias('extinct_on_uncertain', primitive()))
    extinctOnUncertain?: string;
    @serializable(alias('climate_controlled', primitive()))
    climateControlled?: boolean;
    @serializable(alias('wet', primitive()))
    wet?: boolean;
    @serializable(alias('hidden_mickey_associated', primitive()))
    hiddenMickeyAssociated?: boolean;
    @serializable(alias('lat', primitive()))
    lat?: string;
    @serializable(alias('long', primitive()))
    long?: string;
    @serializable(alias('status', primitive()))
    status?: string;
    @serializable(alias('average_wait_time', primitive()))
    averageWaitTime?: number;
    /*@serializable(alias('theme_park_api_ride_id', primitive()))
    themeParkApiRideId?: string;*/
    @serializable(alias('permalink', primitive()))
    permalink?: string;
    @serializable(alias('updated_time', primitive()))
    updatedTime?: string;
    @serializable(alias('ride_type_maps', list(object(RideTypeModel))))
    rideTypeMaps?: string;
    @serializable(alias('ride_theme_maps', list(object(RideThemeModel))))
    rideThemeMaps?: string;
    @serializable(alias('ride_type_ids', list(primitive())))
    rideTypeIds?: string;
    @serializable(alias('theme_ids', list(primitive())))
    themeIds?: string;
    @serializable(alias('attachments', list(object(AttachmentModel))))
    attachments?: string;
    @serializable(alias('is_photo_pass_available', primitive()))
    isPhotopassAvailable?: string;
    @serializable(alias('photo_pass_desc', primitive()))
    photopassDescription?: string;
    @serializable(alias('photo_pass_thumb_url', primitive()))
    photopassThumbUrl?: string;
    @serializable(alias('cubehouse_id', primitive()))
    cubeHouseId?: string;
}

