import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper"
import requiredAuth from "../shared/components/HOC/requireAuth";
import Dashboard from "../views/Dashboard";
import Rides from "../views/RidesList";
import SideBar from "../shared/components/SideBar";
import Restaurants from "../views/RestaurantsList";
import RideDetails from "../views/RidesList/RideDetails";
import RestaurantDetails from "../views/RestaurantsList/RestaurantDetails";
import CrowdCalendar from "../views/CrowdCalendarList";
import ExtraMagicHours from "../views/ExtraMagicHours";
import Users from "../views/Users";
import RainGearShops from "../views/RainGearShops";
import EventDatesManagement from "../views/ExtraMagicHours/EventDates";
import RideForm from "../views/RidesList/RideForm";
import RestaurantForm from "../views/RestaurantsList/RestaurantForm";
import Games from "../views/Games";
import Blogs from "../views/Blogs";

export const appHistory = createBrowserHistory();

const AppRoutes = () => {

    const isAuthenticated = (component: any) => {
        return requiredAuth(component);
    }

    const routes = [
        { exact: false, path: appRoutes.AUTH, component: AuthWrapper },
        { exact: true, path: appRoutes.DASHBOARD, component: isAuthenticated(Dashboard) },
        { exact: true, path: appRoutes.RIDES, component: isAuthenticated(Rides)},
        { exact: true, path: appRoutes.RESTAURANTS, component: isAuthenticated(Restaurants)},
        { exact: true, path: appRoutes.RIDE_DETAILS, component: isAuthenticated(RideDetails)},
        { exact: true, path: appRoutes.RIDE_FORM, component: isAuthenticated(RideForm)},
        { exact: true, path: appRoutes.RESTAURANT_DETAILS, component: isAuthenticated(RestaurantDetails)},
        { exact: true, path: appRoutes.RESTAURANT_FORM, component: isAuthenticated(RestaurantForm)},
        { exact: true, path: appRoutes.CROWD_CALENDAR, component: isAuthenticated(CrowdCalendar)},
        { exact: true, path: appRoutes.EXTRA_MAGIC_HOURS, component: isAuthenticated(ExtraMagicHours)},
        { exact: true, path: appRoutes.USERS, component: isAuthenticated(Users)},
        { exact: true, path: appRoutes.RAIN_GEAR_SHOPS, component: isAuthenticated(RainGearShops)},
        { exact: true, path: appRoutes.EVENT_DATES, component: isAuthenticated(EventDatesManagement)},
        { exact: true, path: appRoutes.GAMES, component: isAuthenticated(Games)},
        { exact: true, path: appRoutes.BLOGS, component: isAuthenticated(Blogs)},
    ]

    return (
        <div>
            <Router history={appHistory}>
                <SideBar/>
                <Switch>
                    {
                        routes.map((route, index) => {
                            return <Route
                                key={index}
                                {...route}
                                component={route.component}
                            />
                        })
                    }
                    <Route path="*" render={() => <Redirect to={appRoutes.LOGIN} />} />
                </Switch>
            </Router>
        </div>
    )
}

export default AppRoutes;
