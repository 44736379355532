import axiosInstance from "../../interceptor/axiosInstance";
import {FETCH_EVENT_DATES, FETCH_EXTRA_MAGIC_HOURS} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {ExtraMagicHoursModel, LocationEventDatesModel} from "../../models/extraMagicHours.model";

export default class ExtraMagicHoursService {

    //get extra magic hours list
    static fetchExtraMagicHours(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(FETCH_EXTRA_MAGIC_HOURS)
            .then(response => {
                const extraMagicHoursList = deserialize(ExtraMagicHoursModel, response.data["extra_magic_hours"]);
                onSuccess(extraMagicHoursList);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete an extra magic hour row
    static deleteExtraMagicHour(
        id:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(FETCH_EXTRA_MAGIC_HOURS+'/'+id)
            .then(response => {
                onSuccess();
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to delete the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //get location wise event dates
    static fetchEventDates(
        locationId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(FETCH_EVENT_DATES+`/${locationId}`)
            .then(response => {
                const eventDates = deserialize(LocationEventDatesModel, response.data["location"]);
                onSuccess(eventDates);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //update location wise event dates
    static updateEventDates(
        locationId: string,
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(FETCH_EVENT_DATES+`/${locationId}`,data)
            .then(response => {
                onSuccess(response);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
