import {Field, Form, Formik} from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import {Button, DatePicker, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {fetchMetaValues} from "../../../shared/utils/fetchMeta";

const EventCreateModal = (props:any) => {
    const {visible,setVisible,onSubmit} = props;

    useEffect(()=>{
        fetchMetaValues('locations',setLocationMeta);
    },[])

    const [locationMeta,setLocationMeta] = useState([]);

    const initialValues ={
        location:'',
        date:''
    }

    return(
        <Modal
            title='Add dates'
            centered
            onCancel={()=>setVisible(false)}
            visible={visible}
            footer={null}
            className='event-dates-modal'
        >
            <Formik initialValues={initialValues}
                    onSubmit={async(values,{resetForm})=>
                    {await onSubmit(values,resetForm)}}
                    enableReinitialize>
                {({
                      setFieldValue
                  }) => {
                    return (
                        <Form>
                            <div className="event-form-field">
                                <div className="label">Select a location</div>
                                <DropdownField placeholder="Location" options={locationMeta}
                                               mode="single" name="location" onChange={(value:any)=>{
                                    setFieldValue('location',value)}
                                }/>
                            </div>
                            <div className="event-form-field">
                                <div className="label">Select a date</div>
                                <Field name="date">
                                    {
                                        ()=> <DatePicker onChange={(dateValue:any,dateString:any)=>
                                            setFieldValue('date',dateString)}/>
                                    }
                                </Field>
                            </div>
                            <div className="form-actions">
                                <Button key="back" type="text" onClick={()=>setVisible(false)}>Cancel</Button>
                                <Button key="approve" type="primary" htmlType="submit">Add</Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default EventCreateModal;
