import React, {useEffect, useState} from "react";
import './users.scss';
import TabsTable from "../../shared/components/TabsTable";
import {Space, Input} from "antd";
import {EyeFilled} from "@ant-design/icons/lib";
import UserManagementService from "../../services/UserService/user.service";
import UserDetails from "./UserDetails";

const {Search} = Input;

const Users = (props: any) => {

    useEffect(()=>{
        fetchUsersList('');
    },[]);

    const fetchUsersList = (filterName: string) => {
        setTableLoading(true)
        UserManagementService.fetchUsersList(filterName,
            (users: any)=>{
                users.map((user: { inviteId: string; proEnabled: string; }) => {
                    user.inviteId = user?.inviteId ? 'Yes' : 'No';
                    user.proEnabled = user?.proEnabled ? 'Yes' : 'No';
                    return user;
                })
                setUserList(users)
            },
            ()=>{},
            ()=>{
                setTableLoading(false)
            },
        ).then()
    };

    const [userList,setUserList]= useState<any>();
    const [userId,setUserId]= useState<any>();
    const [showUserDetails,setShowUserDetails] = useState<boolean>(false);
    const [tableLoading,setTableLoading] = useState<boolean>(false);

    const handleViewUser = (record: { name?: React.ReactNode; id?: any; }) =>{
        setUserId(record?.id)
        setShowUserDetails(true)
    }

    const userColumns = [
        {
            title: 'User ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'User name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Joined from invite',
            dataIndex: 'inviteId',
            key: 'inviteId',
        },
        {
            title: 'Number of planned trips',
            dataIndex: 'numberOfPlans',
            key: 'numberOfPlans',
        },
        {
            title: 'Pro member',
            dataIndex: 'proEnabled',
            key: 'proEnabled',
        },
        {
            title: 'Actions',
            key:'action',
            render: (text: any, record: { name: React.ReactNode; }) => (
                <Space size="middle">
                    <span className="table-action-icons" onClick={()=>{handleViewUser(record)}}><EyeFilled /></span>
                </Space>
            ),
        },
    ];

    const tableProps = [
        {
            key:"1",
            tabName:"ALL USERS",
            dataSource: userList,
            columns: userColumns,
            loading: tableLoading
        }
    ];


    return (
        <div className="component-styles">
            <Search placeholder="Input search text" onSearch={(value:string) => fetchUsersList(value)} enterButton />
            <TabsTable tableProps={tableProps}/>
            <UserDetails userId={userId}
                         showUserDetails={showUserDetails}
                         setShowUserDetails={setShowUserDetails}
            />
        </div>
    )
}

export default Users;
