import React, {useEffect, useState} from "react";
import '../rainGearShops.scss';
import {Button} from "antd";
import {Form, Formik} from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import {fetchMetaValues} from "../../../shared/utils/fetchMeta";
import RainGearShopService from "../../../services/RainGearShopService/rainGearShop.service";
import {serialize} from "serializr";
import {RainGearShopsList} from "../../../models/rainGearShops.model";

const RainGearShopForm = (props: any) => {

    const {setVisible, shopId, shopData, handleClose} = props;

    useEffect(()=>{
        fetchMetaValues('parks',setParksMeta);
        fetchMetaValues('locations',setLocationsMeta);
        constructInitialValues(shopId ? shopData : {})
    },[shopData]);

    const[parksMeta,setParksMeta]= useState([]);
    const[locationsMeta,setLocationsMeta]= useState([]);
    const[initialValues,setInitialValues]= useState<any>();

    const onSubmit = (values:any,resetForm: Function)=>{
        if(values.geoCoordinates){
            values.lat = values.geoCoordinates.split(',')[0];
            values.long = values.geoCoordinates.split(',')[1];
        }
        if(values.purchasableItems){
            values.purchasableItems = values.purchasableItems.split(',');
        }

        values = serialize(RainGearShopsList,values);

        let data = {rain_gear_shop: values};

        if(shopId){
            RainGearShopService.updateRainGearShopDetails(shopId,data,
                ()=>{
                    resetForm(initialValues)
                    handleClose();
                },
                ()=>{},
                ()=>{},
            ).then()
        }else{
            RainGearShopService.createRainGearShop(data,
                ()=>{
                    resetForm(initialValues)
                    handleClose()
                },
                ()=>{},
                ()=>{},
            ).then()
        }
    };

    const constructInitialValues = (shopData: any) => {
        const initialValues = {
            parkLocation:shopData?.parkLocation ? shopData.parkLocation : '',
            description:shopData?.description ? shopData.description : '',
            parkId:shopData?.parkId ? shopData.parkId : '',
            purchasableItems:shopData?.purchasableItems ? shopData.purchasableItems.join(',') : '',
            name:shopData?.name ? shopData.name :"",
            geoCoordinates:(shopData?.lat || 0 )+','+(shopData?.long || 0),
            status:shopData?.status
        }
        setInitialValues(initialValues)
    }

    const statusMeta = [
        {label: 'Active', value: 'Active'},
        {label: 'Inactive', value: 'Inactive'},
    ]


    return (
            <Formik initialValues={initialValues}
                    onSubmit={async(values,{resetForm})=>
                    {await onSubmit(values,resetForm)}}
                    enableReinitialize>
                {({
                      handleChange
                  }) => {
                    return (
                        <Form>
                            <div className="rain-gear-form">
                                <div className="label">Park location</div>
                                <DropdownField placeholder="Park Location" options={locationsMeta}
                                               mode="single" name="parkLocation" onChange={(value: any) => {
                                    handleChange("parkLocation")(value.toString())
                                }}/>
                            </div>
                            <div className="rain-gear-form">
                                <div className="label">Shop description</div>
                                <InputField type="textarea" name="description" placeholder="Shop description"/>
                            </div>
                            <div className="rain-gear-form">
                                <div className="label">Park name</div>
                                <DropdownField placeholder="Park name" options={parksMeta}
                                               mode="single" name="parkId" onChange={(value: any) => {
                                    handleChange("parkId")(value.toString())
                                }}/>
                            </div>
                            <div className="rain-gear-form">
                                <div className="label">Purchasable Items</div>
                                <InputField type="text" name="purchasableItems" placeholder="Purchasable items"/>
                            </div>
                            <div className="rain-gear-form">
                                <div className="label">Shop name</div>
                                <InputField type="text" name="name" placeholder="Shop name"/>
                            </div>
                            {shopId &&
                            <div className="rain-gear-form">
                                <div className="label">Shop status</div>
                                <DropdownField placeholder="Shop status" options={statusMeta}
                                               mode="single" name="status" onChange={(value: any) => {
                                    handleChange("status")(value)
                                }}/>
                            </div>}
                            <div className="rain-gear-form">
                                <div className="label">Geo Coordinates (lat,long)</div>
                                <InputField type="text" name="geoCoordinates" placeholder="Enter geo coordinates"/>
                            </div>
                            <div className="form-actions">
                                <Button key="back" type="text" onClick={() =>{setVisible(false)}}>Cancel</Button>
                                <Button key="approve" type="primary" htmlType="submit">Submit</Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
    )
}

export default RainGearShopForm;
