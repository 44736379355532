import axiosInstance from "../../interceptor/axiosInstance";
import Notification from "../../shared/components/Notification";
import {NotificationTypes} from "../../enums/notificationTypes";

export default class BulkUploadService{
    //get bulk upload template
    static fetchTemplate(
        url: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(url,{ responseType: 'blob' })
            .then(response => {
                onSuccess(response.data);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the template",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
    
    static uploadFile(
        url: string,
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(url,data,{headers:{"Content-Type":'multipart/form-data'}})
            .then(response => {
    
                onSuccess(response);
           
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to upload file",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //get bulk upload template
    static validateFile(
        url: string,
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(url,data,{headers:{"Content-Type":'multipart/form-data'}})
            .then(response => {
                onSuccess(response?.data);
                Notification({
                    message: "File validated",
                    description: "",
                    type: NotificationTypes.SUCCESS
                });
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the template",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //publish bulk uploaded data
    static publishFile(
        url: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void,
        jobId?:number
    ) {
        return axiosInstance
            .post(url,{job_id:jobId})
            .then(response => {
                onSuccess(response);
                Notification({
                    message: "File published",
                    description: "",
                    type: NotificationTypes.SUCCESS
                });
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to publish",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
