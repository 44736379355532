import React, { FC } from "react";
import { Field, ErrorMessage } from "formik";
import { Select } from 'antd';
import Error from "../Error";

interface DropdownFieldProps {
    onChange: Function;
    placeholder: string;
    options: Array<{ value: React.ReactText; label: React.ReactNode; }>;
    name: string;
    mode: any;
}

const DropdownField: FC<DropdownFieldProps> = (props) => {
    const {name}=props;
    return (
        <div>
            <Field as={Select} {...props}/>
            <ErrorMessage name={name}>
                {(message: string) => <Error message={message} />}
            </ErrorMessage>
        </div>
    )
}

export default DropdownField;
