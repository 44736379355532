import React, {useEffect, useState} from "react";
import GamesService from "../../../services/GamesService/games.service";
import {Button} from "antd";
import {BulkUploadModal} from "../../../shared/components/BulkUploadModal";
import {
    TRIVIA_PUBLISH_FILE,
    TRIVIA_TEMPLATE_URL,
    TRIVIA_VALIDATE_FILE
} from "../../../routes/routeConstants/apiRoutes";
import excelIcon from "../../../assets/excel_icon.png";
import CircularLoader from "../../../shared/components/CircularLoader";

const Trivia = () =>{

    const [triviaCount,setTriviaCount] = useState<any>();
    const [showBulkUpload, setShowBulkUpload] = useState<boolean>(false);
    const [bulkUploadAction, setBulkUploadAction] = useState<string>('upload');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(()=>{
        refreshCount()
    },[]);

    const refreshCount = () =>{
        setLoading(true)
        GamesService.fetchTriviaCount(
            (response:any)=>{
                setTriviaCount(response)
            },
            ()=>{},
            ()=>{
                setLoading(false)
            },
        ).then()
    }

    return (
        <div>
            <Button type='primary' className='game-button' onClick={()=>{
                setShowBulkUpload(true)
            }}>Bulk Upload</Button>
            {loading && <CircularLoader/>}
            {!loading && <div className="trivia-card">
                <div className="trivia-card__image">
                    <img src={excelIcon} alt='excel'/>
                </div>
                <div className='trivia-card__details'>
                    <div className='trivia-card__title'>Trivia Questions and Answers</div>
                    <div className='trivia-card__count'>{triviaCount || '-'} Questions</div>
                </div>
            </div>}
            <BulkUploadModal showBulkUpload={showBulkUpload}
                             setShowBulkUpload={setShowBulkUpload}
                             bulkUploadAction={bulkUploadAction}
                             setBulkUploadAction={setBulkUploadAction}
                             templateUrl={TRIVIA_TEMPLATE_URL}
                             validationUrl={TRIVIA_VALIDATE_FILE}
                             publishUrl={TRIVIA_PUBLISH_FILE}
                             refreshData={refreshCount}
                             bulkUploadFor='trivia'
            />
        </div>
    )
}

export default Trivia;
