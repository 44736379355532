import React, {FC, useState} from "react";
import {DeleteConfirmation} from "../../../shared/components/DeleteConfirmation";
import AttachmentService from "../../../services/attachment.service";

interface AttractionImagesProps{
    attractionValues: any;
    refreshAttraction: Function;
    labelText: string;
}

const AttractionImages:FC<AttractionImagesProps> = (props) => {

    const {attractionValues,refreshAttraction, labelText} = props;

    const [currentAttachmentId, setCurrentAttachmentId] = useState<any>('');
    const [showDelete, setShowDelete] = useState<boolean>(false);

    const setDelete = (attachment:any) => {
        setCurrentAttachmentId(attachment?.id)
        setShowDelete(true);
    }

    const handleAttachmentDelete = () => {

        AttachmentService.deleteAttachment(currentAttachmentId,
            ()=>{
                setShowDelete(false);
                setCurrentAttachmentId('');
                setTimeout(()=>refreshAttraction(),200);
            },
            ()=>{},
            ()=>{},
        ).then()
    }

    return(
        <div className="photos-for-ride">
            <DeleteConfirmation deleteVisible={showDelete}
                                setDeleteVisible={setShowDelete}
                                text='attachment'
                                handleDelete={handleAttachmentDelete}
            />
            <div className="label">
                {labelText}
            </div>
            {attractionValues?.attachments?.map((attachment:any,i:number)=>
                <div className='image-container' key={i}>
                    <span className='image-delete-icon' onClick={()=>setDelete(attachment)}>x</span>
                    <img src={attachment.imageUrl} alt='ride' key={i}/>
                </div>
            )}
        </div>
    )
}

export  default AttractionImages;
