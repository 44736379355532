import {Form, Formik} from "formik";
import {validationSchema} from "./validation";
import InputField from "../../../shared/components/InputField";
import {Button} from "antd";
import React, {useState} from "react";
import UserService from "../../../services/AuthService/auth.service";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import {withRouter} from "react-router-dom"

const ForgotPassword = (props:any) =>{

    const [loading,setLoading] = useState<boolean>(false)

    const forgotInitialValue = {
        email: "",
    }

    const onForgotSubmit = async(values: { email: Object; })=>{
        setLoading(true)
        let data = {user: values}
        UserService.submitEmailForOtp(data,
            ()=>{
                setLoading(false)
                props.history.push(appRoutes.RESET_PASSWORD,{email:values?.email})
            },
            ()=>{setLoading(false)},
            ()=>{},
        ).then()
    };

    return(
        <div className="login-form">
            <div className="login-form__title">Enter the registered email id</div>
            <Formik
                initialValues={forgotInitialValue}
                onSubmit={onForgotSubmit}
                validationSchema={validationSchema}
            >
                <Form>
                    <div className='login-form__field'>
                        <div className="label">Email</div>
                        <InputField type="email" name="email" placeholder="Enter email" />
                    </div>
                    <Button htmlType="submit" className="login-form__submit" loading={loading}>Submit</Button>
                </Form>
            </Formik>
        </div>
    )
}

export default withRouter(ForgotPassword)
