import axiosInstance from "../../interceptor/axiosInstance";
import {USER_PROFILES} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {UserDetailsModel, UserListModel} from "../../models/user.model";

export default class UserManagementService {

    //get crowd calendar
    static fetchUsersList(
        name: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        let URL = name ? USER_PROFILES+`?name=${name}` : USER_PROFILES
        return axiosInstance
            .get(URL)
            .then(response => {
                const userProfiles = deserialize(UserListModel, response.data["users"]);
                onSuccess(userProfiles);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
    static fetchUserDetails(
        userId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(USER_PROFILES+`/${userId}`)
            .then(response => {
                const userDetails = deserialize(UserDetailsModel, response.data["user"]);
                onSuccess(userDetails);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
