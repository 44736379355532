import {  UNAUTHENTICATED } from "../definitions/authConstants";

export const setUnAuthenticated = () => {
    return {
        type: UNAUTHENTICATED,
        payload: {
            authenticated: false,
            user: null
        }
    }
}