import axiosInstance from "../../interceptor/axiosInstance";
import {RAIN_GEAR_SHOPS} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {RainGearShopsList} from "../../models/rainGearShops.model";

export default class RainGearShopService {

    //get rain gear shops
    static fetchRainGearShops(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(RAIN_GEAR_SHOPS)
            .then(response => {
                const rainGearShops = deserialize(RainGearShopsList, response.data["rain_gear_shops"]);
                onSuccess(rainGearShops);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //get rain gear shop details
    static fetchRainGearShopDetails(
        shopId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(RAIN_GEAR_SHOPS+`/${shopId}`)
            .then(response => {
                const rainGearShop = deserialize(RainGearShopsList, response.data["rain_gear_shop"]);
                onSuccess(rainGearShop);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //update rain gear shop details
    static updateRainGearShopDetails(
        shopId: string,
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(RAIN_GEAR_SHOPS+`/${shopId}`,data)
            .then(response => {
                const rainGearShop = deserialize(RainGearShopsList, response.data["rain_gear_shop"]);
                onSuccess(rainGearShop);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //create rain gear shop
    static createRainGearShop(
        data: any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(RAIN_GEAR_SHOPS,data)
            .then(response => {
                const rainGearShop = deserialize(RainGearShopsList, response.data["rain_gear_shop"]);
                onSuccess(rainGearShop);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete rain gear shop details
    static deleteRainGearShop(
        shopId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(RAIN_GEAR_SHOPS+`/${shopId}`)
            .then(() => {
                onSuccess();
                Notification({
                    message: "Deleted",
                    description: "Rain gear has been deleted successfully",
                    type: NotificationTypes.SUCCESS
                });
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to delete the rain gear shop",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
