import {serializable, alias, primitive, list} from 'serializr';

export class BlogsModel {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('id', primitive()))
    key?: string;
    @serializable(alias('title', primitive()))
    title?: string;
    @serializable(alias('tags', list(primitive())))
    tags?: string;
    @serializable(alias('author', primitive()))
    author?: string;
    @serializable(alias('status', primitive()))
    status?: string;
    @serializable(alias('preview_text', primitive()))
    previewText?: string;
    @serializable(alias('created_at', primitive()))
    createdAt?: string;
    @serializable(alias('image_url', primitive()))
    imageUrl?: string;
    @serializable(alias('image_thumb_url', primitive()))
    imageThumbUrl?: string;
    @serializable(alias('content', primitive()))
    content?: string;
}
