import { notification } from 'antd';
import { NotificationTypes } from "../../../enums/notificationTypes";

interface NotificationProps {
    message: string;
    description: string;
    type: string
}

const Notification = ({ message, description, type }: NotificationProps) => {
    
    switch (type) {
        case NotificationTypes.SUCCESS:
            return notification.success({ message, description });
        case NotificationTypes.ERROR:
            return notification.error({ message, description });
        case NotificationTypes.WARNING:
            return notification.warning({ message, description });
        case NotificationTypes.INFO:
            return notification.info({ message, description });
        case NotificationTypes.OPEN:
            return notification.open({ message, description });
        default:
            notification.open({ message, description })
    }
};

export default Notification; 