import axiosInstance from "../../interceptor/axiosInstance";
import {
    DELETE_PENDING_RESTAURANT,
    LIVE_RESTAURANTS_LIST,
    PENDING_RESTAURANTS_LIST,
    RESTAURANTS_DETAILS,
    RESTAURANTS_COMPARE,
} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {generatePath} from "react-router-dom";
import {RestaurantDetailsModel, RestaurantList} from "../../models/restaurant.model";

export default class RestaurantService {

    //get pending restaurant list
    static fetchPendingRestaurantsList(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(PENDING_RESTAURANTS_LIST)
            .then(response => {
                const restaurantList =  deserialize(RestaurantList,response.data["external_api_restaurants"]);
                onSuccess(restaurantList);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to fetch the data",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //get restaurant list
    static fetchLiveRestaurantsList(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(LIVE_RESTAURANTS_LIST)
            .then(response => {
                const restaurantList =  deserialize(RestaurantList,response.data["restaurants"]);
                onSuccess(restaurantList);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to fetch the data",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //get data for comparison
    static fetchRestaurantsCompare(
        restaurantId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(generatePath(RESTAURANTS_COMPARE,{id:restaurantId}))
            .then(response => {
                const restaurantsData = response.data['compare_data']
                onSuccess(restaurantsData);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to fetch the data",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //get restaurant details
    static fetchRestaurantDetails(
        restaurantId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(generatePath(RESTAURANTS_DETAILS,{id:restaurantId}))
            .then(response => {
                const restaurantDetails = deserialize(RestaurantDetailsModel,response.data['restaurant']);
                onSuccess(restaurantDetails);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to fetch the details",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }


    //delete a pending restaurant
    static deletePendingRestaurant(
        restaurantId: string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(generatePath(DELETE_PENDING_RESTAURANT,{id:restaurantId}))
            .then(response => {
                onSuccess(response);
                Notification({ message: "Deleted", description: "Restaurant has been deleted successfully",type: NotificationTypes.SUCCESS });
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to delete",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //update live restaurant
    static updateLiveRestaurant(
        restaurantId: string,
        data: object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(generatePath(RESTAURANTS_DETAILS,{id:restaurantId}),data)
            .then(response => {
                Notification({ message: "Update", description: "Updated successfully", type: NotificationTypes.SUCCESS });
                onSuccess(response);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to update",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //create new restaurant
    static createNewRestaurant(
        data: object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(LIVE_RESTAURANTS_LIST,data)
            .then(response => {
                Notification({ message: "Restaurant submission", description: "Completed successfully", type: NotificationTypes.SUCCESS });
                onSuccess(response['data']);
            })
            .catch(error => {
                Notification({ message: "Error", description: "Unable to post the data",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }
}
