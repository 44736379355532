import {Button, Modal} from "antd";
import React from "react";
import './deleteConfirmation.scss';

export const DeleteConfirmation = (props:any) =>{

    const {deleteVisible,setDeleteVisible,text,handleDelete } = props;
    return(
        <Modal
            title="Delete confirmation"
            centered
            onCancel={()=>setDeleteVisible(false)}
            visible={deleteVisible}
            footer={null}
            className='delete-modal'
        >
            <div className='delete-modal__text'>Are you sure to delete this {text}?</div>

            <div className="delete-modal__actions">
                <Button key="back" type="text" onClick={()=>{setDeleteVisible(false)}}>Cancel</Button>
                <Button key="delete" type="primary" onClick={()=>handleDelete()}
                    className='delete-modal__primary-button'>Delete</Button>
            </div>
        </Modal>
)}
