import axiosInstance from "../../interceptor/axiosInstance";
import {BLOGS} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {BlogsModel} from "../../models/blogs.model";

export default class BlogsService {

    //get blogs list
    static fetchBlogs(
        searchString:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        let URL = searchString ? BLOGS+`?search_text=${searchString}` : BLOGS;
        return axiosInstance
            .get(URL)
            .then(response => {
                const blogs = deserialize(BlogsModel,response.data['blogs'])
                onSuccess(blogs);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch blogs list",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //get blog data
    static fetchBlogDetails(
        blogId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(BLOGS+`/${blogId}`)
            .then(response => {
                const blog = deserialize(BlogsModel,response.data['blog'])
                onSuccess(blog);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch blogs list",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //create blog
    static createBlog(
        data:any,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(BLOGS,data)
            .then(response => {
                onSuccess();
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to create the blog",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //update blog
    static updateBlog(
        data:any,
        blogId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(BLOGS+`/${blogId}`,data)
            .then(response => {
                onSuccess();
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to update the blog",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete blog
    static deleteBlog(
        blogId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(BLOGS+`/${blogId}`)
            .then(response => {
                onSuccess();
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to delete the blog",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }

    //delete blog image
    static deleteBlogImage(
        blogId:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(BLOGS+`/${blogId}/delete_image`)
            .then(() => {
                onSuccess();
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to delete the image",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
