import React, {useEffect, useState} from "react";
import '../RestaurantDetails/restaurantDetails.scss';
import {LeftSquareFilled} from "@ant-design/icons/lib";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import {withRouter} from "react-router";
import {Formik, Form, Field} from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import {Button, Rate} from "antd";
import RestaurantService from "../../../services/RestaurantService/restaurant.service";
import {fetchMetaValues} from "../../../shared/utils/fetchMeta";
import moment from "moment";
import {serialize} from "serializr";
import {generatePath} from "react-router-dom";
import {RestaurantDetailsModel} from "../../../models/restaurant.model";
import FileUpload from "../../../shared/components/FileUpload";
import {convertJSONToFormData} from "../../../shared/utils/jsonToFormdata";
import CircularLoader from "../../../shared/components/CircularLoader";
import AttractionImages from "../../RidesList/RideImages";

const RestaurantForm = (props: any) => {

    const {restaurantId}  = props.match.params;

    useEffect(()=>{
        fetchMetaValues("parks",setParksMeta)
        fetchMetaValues("cuisines",setCuisinesMeta)
        window.scroll(0,0)
        if(restaurantId !== 'new') {
            fetchRestaurantDetails();
        }else{
            setFormValues({})
        }
    },[restaurantId]);

    const fetchRestaurantDetails = () => {
        setLoading(true)
        RestaurantService.fetchRestaurantDetails(restaurantId,
            (restaurantValues: any) => {
                setFormValues(restaurantValues);
                setRestaurantValues(restaurantValues);
            },
            () => {
            },
            () => {
                setLoading(false)
            }
        ).then()
    }

    const [parksMeta, setParksMeta] = useState([]);
    const [cuisinesMeta, setCuisinesMeta] = useState([]);
    const [restaurantValues, setRestaurantValues] = useState<any>([]);
    const [initialValues, setInitialValues] = useState({});
    const [restaurantPhotos, setRestaurantPhotos] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);


    const onSubmit = (restaurantDetails: any) => {
        if(restaurantDetails.geoCoordinates){
            restaurantDetails.lat = restaurantDetails.geoCoordinates.split(',')[0];
            restaurantDetails.long = restaurantDetails.geoCoordinates.split(',')[1];
        }

        if(restaurantDetails.costCode){
            restaurantDetails.costCode = restaurantDetails.costCode  === '$$$' ? "expensive" :
                restaurantDetails.costCode  === '$$' ? "moderate" :
                    restaurantDetails.costCode  === '$' ? "inexpensive" : '-';
        }

        let data = {restaurant: serialize(RestaurantDetailsModel,restaurantDetails)};

        if(restaurantPhotos.length > 0){
            data.restaurant.attachments = restaurantPhotos.map((element:any) => element.originFileObj)
        }

        data.restaurant.optional_params = false;
        
        Object.keys(data.restaurant).forEach(key => data.restaurant[key] === undefined && delete data.restaurant[key])

        let formData = convertJSONToFormData(data);

        if(restaurantId !== 'new') {
            RestaurantService.updateLiveRestaurant(restaurantId, formData,
                () => {
                    let URL = generatePath(appRoutes.RESTAURANT_DETAILS, {restaurantId: restaurantId})
                    props.history.push(URL)
                },
                () => {},
                () => {},
            ).then()
        }else{
            RestaurantService.createNewRestaurant(formData,
                (response: { restaurant: any; }) => {
                    let restaurantId = response.restaurant?.id;
                    let URL = generatePath(appRoutes.RESTAURANT_DETAILS, {restaurantId: restaurantId})
                    props.history.push(URL)
                },
                () => {},
                () => {},
            ).then()
        }
    };

    const setFormValues = (restaurantValues: any) => {
        setInitialValues({
            name: restaurantValues?.name,
            parkId: restaurantValues?.parkId?.toString(),
            costCode: restaurantValues?.costCode ?
                (restaurantValues.costCode  === 'expensive' ? "$$$" :
                    restaurantValues.costCode  === 'moderate' ? "$$" :
                        restaurantValues.costCode  === 'inexpensive' ? "$" : '-') :'-' ,
            overallRating: restaurantValues?.overallRating ? restaurantValues.overallRating : 5,
            bar: restaurantValues?.bar ? 'true': 'false',
            wineList:  restaurantValues?.wineList ? 'true': 'false',
            notableSnack: restaurantValues?.notableSnack,
            geoCoordinates: (restaurantValues?.lat ? restaurantValues.lat : 0) +','+
                (restaurantValues?.long? restaurantValues.long : 0),
            breakfastHours:restaurantValues?.breakfastHours,
            lunchHours:restaurantValues?.lunchHours,
            dinnerHours:restaurantValues?.dinnerHours,
            cuisineIds:restaurantValues.restaurantCuisineMaps?.map((item:any)=>item.cuisineId.toString()),
            nearByPlaces:restaurantValues?.nearByPlaces,
            selection: restaurantValues?.selection,
            settingAtmosphere: restaurantValues?.settingAtmosphere,
            houseSpecialities: restaurantValues?.houseSpecialities,
            isQuickServiceAvailable: restaurantValues?.isQuickServiceAvailable ? 'true': 'false',
            breakfastUrl: restaurantValues?.breakfastUrl,
            brunchUrl: restaurantValues?.brunchUrl,
            lunchUrl: restaurantValues?.lunchUrl,
            snacksUrl: restaurantValues?.snacksUrl,
            dinnerUrl: restaurantValues?.dinnerUrl,
            otherMenuUrl: restaurantValues?.otherMenuUrl,
        })
    }

    const booleanMeta = [
        {value: "true", label: 'Yes'},
        {value: "false", label: 'No'},
    ];

    return(
        <div className="component-styles">
            <div className="back-button" onClick={()=>props.history.push(appRoutes.RESTAURANTS)}><LeftSquareFilled />Back</div>
            {loading && <CircularLoader/>}
            {!loading && <div className="ride-details">
                <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                    {({
                          handleChange,
                          values,
                          setFieldValue,
                      }) => {
                        return (
                            <Form>
                                {restaurantId !== 'new' &&
                                <div className="ride-form">
                                    <div className="label">Park location</div>
                                    <div className="value">{restaurantValues?.parkLocation}</div>
                                </div>}

                                <div className="ride-form">
                                    <div className="label">Park name</div>
                                    <DropdownField placeholder="park name" options={parksMeta}
                                                   mode="single" name="parkId" onChange={(value: any) => {
                                        setFieldValue('parkId',value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Restaurant name</div>
                                    <InputField type='text' name='name' placeholder='Restaurant name'/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Expected cost</div>
                                    <InputField type="text" name="costCode" placeholder="Expected cost"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Star rating</div>
                                    <Field name="overallRating">
                                        {
                                            //@ts-ignore
                                            ()=> <Rate value={values.overallRating} allowHalf
                                                       onChange={(value:any)=>setFieldValue('overallRating',value)}/>
                                        }
                                    </Field>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Bar</div>
                                    <DropdownField placeholder="Bar" options={booleanMeta}
                                                   mode="single" name="bar" onChange={(value: any) => {
                                        handleChange("bar")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Wine</div>
                                    <DropdownField placeholder="Wine" options={booleanMeta}
                                                   mode="single" name="wineList" onChange={(value: any) => {
                                        handleChange("wineList")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Updated at</div>
                                    <div className="value">{moment(restaurantValues?.updatedTime).format('DD/MM/YYYY')}</div>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Notable snacks</div>
                                    <InputField type="text" name="notableSnack" placeholder="Notable snacks"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Geo coordinates</div>
                                    <InputField type="text" name="geoCoordinates" placeholder="Geo coordinates"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Breakfast hours</div>
                                    <InputField type="text" name="breakfastHours" placeholder="Breakfast hours"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Lunch hours</div>
                                    <InputField type="text" name="lunchHours" placeholder="Lunch hours"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Dinner hours</div>
                                    <InputField type="text" name="dinnerHours" placeholder="Dinner hours"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Quick service</div>
                                    <DropdownField placeholder="Quick service" options={booleanMeta}
                                                   mode="single" name="isQuickServiceAvailable" onChange={(value: any) => {
                                        handleChange("isQuickServiceAvailable")(value)
                                    }}/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Breakfast Menu Url</div>
                                    <InputField name="breakfastUrl" placeholder="Breakfast menu url" type="text"/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Brunch Menu Url</div>
                                    <InputField name="brunchUrl" placeholder="Brunch menu url" type="text"/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Lunch Menu Url</div>
                                    <InputField name="lunchUrl" placeholder="Lunch menu url" type="text"/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Snacks Menu Url</div>
                                    <InputField name="snacksUrl" placeholder="Snacks menu url" type="text"/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Dinner Menu Url</div>
                                    <InputField name="dinnerUrl" placeholder="Dinner menu url" type="text"/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Other Menu Url</div>
                                    <InputField name="otherMenuUrl" placeholder="Other menu url" type="text"/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Permalink</div>
                                    <div className="value"><a href={restaurantValues?.permalink}>{restaurantValues?.permalink}</a></div>
                                </div>

                                <div>
                                    <div className='label'>
                                        Photos for Restaurant <i>(Max 5 photos for each ride) Size should be 255*525</i>
                                    </div>
                                    <div className='image-upload-container'>
                                        <FileUpload fileList={restaurantPhotos} setFileList={setRestaurantPhotos} limit={5}/>
                                    </div>
                                </div>

                                <AttractionImages attractionValues={restaurantValues}
                                                  labelText={'Existing photos for restaurant'}
                                                  refreshAttraction={fetchRestaurantDetails}
                                />

                                <div className="ride-form textarea">
                                    <div className="label">Selection</div>
                                    <InputField name="selection" placeholder="Selection" type="textarea"/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Setting atmosphere</div>
                                    <InputField name="settingAtmosphere" placeholder="Setting atmosphere" type="textarea"/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">House specialities</div>
                                    <InputField name="houseSpecialities" placeholder="House specialities" type="textarea"/>
                                </div>

                                <div className="ride-form multi-select">
                                    <div className="label">Cuisine type</div>
                                    <DropdownField placeholder="Cuisine" options={cuisinesMeta}
                                                   mode="multiple" name="cuisineIds" onChange={(value: any) => {
                                        setFieldValue('cuisineIds',value)
                                    }}/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Nearby places</div>
                                    <InputField name="nearByPlaces" placeholder="Nearby places" type="textarea"/>
                                </div>

                                <div className="form-actions">
                                    <Button key="back" type="text" onClick={()=>props.history.push(appRoutes.RESTAURANTS)}>Cancel</Button>
                                    <Button key="approve" type="primary" htmlType="submit">Save</Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>}
        </div>
    )
}

export default withRouter(RestaurantForm);
