import React from "react";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const CircularLoader = (props:any) => {
    return(
        <Spin indicator={antIcon} className='main-loader'/>
    )
}

export default CircularLoader
