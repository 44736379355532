import React from "react";
import {Table, Tabs} from "antd";
import './rideResTabs.scss';

const TabsTable = (props: any) => {
    const { TabPane } = Tabs;
    const { tableProps } = props;

    return (
        <Tabs defaultActiveKey="1">
            {tableProps.map((tableProp:any) =>{
                return(
                    <TabPane tab={tableProp.tabName} key={tableProp.key} className="">
                        <Table dataSource={tableProp.dataSource} columns={tableProp.columns}
                               loading={tableProp.loading}/>
                    </TabPane>
                )
            })}
        </Tabs>
    )
}

export default TabsTable;
