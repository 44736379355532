// Base URL
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const ACTION_CABLE = "wss://api.bestdisneyday.com/api/v1/cable"

// Login URL
export const USER_LOGIN = "/users/sign_in";
export const USER_LOGOUT = "/users/sign_out";
export const RESET_PASSWORD = "/passwords";

//Rides URL
export const PENDING_RIDES_LIST = "/external_api_rides";
export const LIVE_RIDES_LIST = "/rides";
export const RIDES_COMPARE = "/external_api_rides/:id/compare";
export const RIDE_DETAILS = LIVE_RIDES_LIST+'/:id';
export const DELETE_PENDING_RIDE = PENDING_RIDES_LIST+'/:id';

//Restaurants URL
export const PENDING_RESTAURANTS_LIST = "/external_api_restaurants";
export const LIVE_RESTAURANTS_LIST = "/restaurants";
export const RESTAURANTS_COMPARE = "/external_api_restaurants/:id/compare";
export const RESTAURANTS_DETAILS = LIVE_RESTAURANTS_LIST+'/:id';
export const DELETE_PENDING_RESTAURANT = PENDING_RESTAURANTS_LIST+'/:id';
export const RESTAURANTS_TEMPLATE_URL = '/restaurants/download_template';
export const RESTAURANTS_VALIDATE_FILE = '/restaurants/validate_bulk_import_file';
export const RESTAURANTS_PUBLISH_FILE = '/restaurants/process_bulk_import';

//crowd calendar
export const FETCH_CROWD_CALENDAR = '/crowd_calendars';
export const REFRESH_CROWD_CALENDAR = '/crowd_calendars/import_data';

//extra magic hours
export const FETCH_EXTRA_MAGIC_HOURS = '/extra_magic_hours';
export const FETCH_EVENT_DATES = '/locations';
export const EMH_TEMPLATE_URL = '/extra_magic_hours/download_template';
export const EMH_VALIDATE_FILE = '/extra_magic_hours/validate_bulk_import_file';
export const EMH_PUBLISH_FILE = '/extra_magic_hours/process_bulk_import';

//users
export const USER_PROFILES = '/users';

//rain gear shops
export const RAIN_GEAR_SHOPS = '/rain_gear_shops';

//dashboard
export const DASHBOARD_API = '/dashboards';

//themepark API
export const THEMEPARK_API = '/cubehouse_rides';

//games
export const HIDDEN_MICKEYS = '/hidden_mickeys';
export const TRIVIA_COUNT = '/trivia';
export const TRIVIA_TEMPLATE_URL = '/trivia/download_template';
export const TRIVIA_VALIDATE_FILE = '/trivia/validate_bulk_import_file';
export const TRIVIA_PUBLISH_FILE = '/trivia/process_bulk_import';
export const RIDDLE_COUNT = '/riddles';
export const RIDDLE_TEMPLATE_URL = '/riddles/download_template';
export const RIDDLE_VALIDATE_FILE = '/riddles/validate_bulk_import_file';
export const RIDDLE_PUBLISH_FILE = '/riddles/process_bulk_import';

//blogs
export const BLOGS = '/blogs';

//upload image
export const UPLOAD_IMAGE = '/blogs/upload_image';

//attachments delete
export const ATTACHMENTS_DELETE = 'attachments/:id';
