import React, {useEffect, useState} from "react";
import './restaurants.scss';
import TabsTable from "../../shared/components/TabsTable";
import {Space, Button} from "antd";
import {CloseSquareOutlined, EditFilled, EyeFilled, LeftSquareFilled} from "@ant-design/icons/lib";
import ApprovalView from "../../shared/components/RideRestaurantApproval";
import {generatePath} from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import RestaurantService from "../../services/RestaurantService/restaurant.service";
import moment from "moment";
import {DeleteConfirmation} from "../../shared/components/DeleteConfirmation";
import RestaurantApprovalModal from "./RestaurantApprovalModal";
import CircularLoader from "../../shared/components/CircularLoader";
import {
    EMH_PUBLISH_FILE,
    EMH_TEMPLATE_URL,
    EMH_VALIDATE_FILE, RESTAURANTS_PUBLISH_FILE,
    RESTAURANTS_TEMPLATE_URL, RESTAURANTS_VALIDATE_FILE
} from "../../routes/routeConstants/apiRoutes";
import {BulkUploadModal} from "../../shared/components/BulkUploadModal";

const Restaurants = (props: any) => {
    useEffect(()=>{
        // fetchRestaurantsData(RestaurantService.fetchPendingRestaurantsList, setPendingRestaurantData,setPendingLoading)
        fetchRestaurantsData(RestaurantService.fetchLiveRestaurantsList, setLiveRestaurantData,setLiveLoading)
    },[]);

    const [view,setView] = useState('listing');
    const [pendingRestaurantData, setPendingRestaurantData] = useState([]);
    const [liveRestaurantData, setLiveRestaurantData] = useState([]);
    const [approvalData, setApprovalData] = useState<any>();
    const [restaurantId, setRestaurantId] = useState('');
    const [visible, setVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [parkId, setParkId] = useState('');
    const [existingRestaurantId, setExistingRestaurantId] = useState('');
    const [optionalParams,setOptionalParams] = useState({});
    const [pendingLoading,setPendingLoading] = useState<boolean>(false);
    const [liveLoading,setLiveLoading] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [bulkUploadAction, setBulkUploadAction] = useState('upload');

    const fetchRestaurantsData = (apiFunction: Function, stateFn: Function, loaderFn:Function) => {
        loaderFn(true)
        apiFunction(
            (list: any) => {
                list.map((item: { lastUpdatedOn: string | number | Date; }) => {
                    item.lastUpdatedOn = moment(item.lastUpdatedOn).format("DD/MM/YYYY");
                })
                stateFn(list)
            },
            () => {},
            () => {loaderFn(false)}
        )
    };

    const handleCompare = (record: any)=>{
        setLoading(true)
        setRestaurantId(record.restaurantId);
        setExistingRestaurantId(record.existingRestaurantId);
        RestaurantService.fetchRestaurantsCompare(record.restaurantId,
            (restaurantsData: any)=>{
                setParkId(restaurantsData['external_api_restaurant']['park_id'])
                let approvalDataForCompare = [];
                let restaurantDetailsColumns: string[] = [];

                //get the columns
                if(Object.keys(restaurantsData['external_api_restaurant']).length > 0) {
                    restaurantDetailsColumns = Object.keys(restaurantsData['external_api_restaurant'])
                }

                //to hide id and update time fields from ui
                if(restaurantDetailsColumns.length>0){
                    restaurantDetailsColumns = restaurantDetailsColumns.filter(column => !column.includes('id'))
                    restaurantDetailsColumns = restaurantDetailsColumns.filter(column => !column.includes('created_at'))
                    restaurantDetailsColumns = restaurantDetailsColumns.filter(column => !column.includes('time'))
                    restaurantDetailsColumns = restaurantDetailsColumns.filter(column => !column.includes('menu'))
                }

                let i=1;
                //data for comparison
                for(let column of restaurantDetailsColumns){
                    approvalDataForCompare.push(
                        {
                            key:i,
                            restaurantDetails: column,
                            changedValue: typeof restaurantsData['external_api_restaurant'][column] === "boolean" ?
                                (restaurantsData['external_api_restaurant'][column] ? 'Yes' : 'No') :
                                (restaurantsData['external_api_restaurant'][column] ? restaurantsData['external_api_restaurant'][column] : '-'),
                            existingValue: restaurantsData['restaurant'] ?
                                (typeof restaurantsData['restaurant'][column] === "boolean" ? (restaurantsData['restaurant'][column] ? 'Yes' : 'No') :
                                    (restaurantsData['restaurant'][column] ? restaurantsData['restaurant'][column] : '-')):'-'
                        }
                    )
                    i++;
                }
                setApprovalData(approvalDataForCompare)
                setView('approval');
            },
            ()=>{},
            ()=>{
                setLoading(false)
            },
        ).then()
    };

    const handleDelete = () =>{
        RestaurantService.deletePendingRestaurant(restaurantId,
            ()=>{
                fetchRestaurantsData(RestaurantService.fetchPendingRestaurantsList, setPendingRestaurantData,setPendingLoading)
                setDeleteVisible(false);
                handleBack()
            },
            ()=>{},
            ()=>{},
        ).then()
    }

    const handleApprove = (restaurantId: string,existingRestaurantId:string)=>{
        setRestaurantId(restaurantId);
        if(existingRestaurantId){
            setLoading(true)
            let data={restaurant:{external_api_restaurant_id: existingRestaurantId,optional_params: false}};
            RestaurantService.updateLiveRestaurant(existingRestaurantId, data,
                () => {
                    fetchRestaurantsData(RestaurantService.fetchPendingRestaurantsList, setPendingRestaurantData,setPendingLoading)
                    fetchRestaurantsData(RestaurantService.fetchLiveRestaurantsList, setLiveRestaurantData,setLiveLoading)
                },
                () => {},
                () => {
                    setLoading(false)
                },
            ).then()
        }else{
            setVisible(true);
        }
    };

    const handleOptionalParamsApprove = (existingRestaurantId:string)=>{
        if(existingRestaurantId){
            setLoading(true)
            let data:any = {restaurant:optionalParams};

            //to remove undefined key value pairs
            Object.keys(data.restaurant).forEach(key => data.restaurant[key] === undefined && delete data.restaurant[key])

            RestaurantService.updateLiveRestaurant(existingRestaurantId, data,
                () => {
                    fetchRestaurantsData(RestaurantService.fetchPendingRestaurantsList, setPendingRestaurantData,setPendingLoading)
                    fetchRestaurantsData(RestaurantService.fetchLiveRestaurantsList, setLiveRestaurantData,setLiveLoading)
                    handleBack()
                },
                () => {},
                () => {
                    setLoading(false)
                },
            ).then()
        }else{
            setVisible(true);
        }
    }

    const handleActions = (record: { name?: React.ReactNode; restaurantId?: string; },action: string) => {
        let baseUrl = action === 'view' ? appRoutes.RESTAURANT_DETAILS :
                action === 'edit' ? appRoutes.RESTAURANT_FORM : '';
        let URL = generatePath(baseUrl,{restaurantId:record.restaurantId})
        props.history.push(URL)
    }

    const pendingColumns = [
        {
            title: 'Restaurant ID',
            dataIndex: 'restaurantId',
            key: 'restaurantId',
        },
        {
            title: 'Restaurant Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Park Location',
            dataIndex: 'parkLocation',
            key: 'parkLocation',
        },
        {
            title: 'Park Name',
            dataIndex: 'parkName',
            key: 'parkName',
        },
        {
            title: 'Last updated on',
            dataIndex: 'lastUpdatedOn',
            key: 'lastUpdatedOn',
        },
        {
            title: 'Actions',
            key:'action',
            render: (text: any, record: {
                existingRestaurantId: string;
                restaurantId: string; name: React.ReactNode; }) => (
                <Space size="middle">
                    <Button className="table-approve-button" onClick={()=>{handleApprove(record.restaurantId, record.existingRestaurantId)}}>Approve</Button>
                    <span className="table-action-icons" onClick={()=>{handleCompare(record)}}><EyeFilled /></span>
                    <span className="table-action-icons" onClick={()=>{
                        setDeleteVisible(true)
                        setRestaurantId(record.restaurantId)
                    }}><CloseSquareOutlined /></span>
                </Space>
            ),
        },
    ];

    const liveColumns = [
        {
            title: 'Restaurant ID',
            dataIndex: 'restaurantId',
            key: 'restaurantId',
        },
        {
            title: 'Restaurant Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Park Location',
            dataIndex: 'parkLocation',
            key: 'parkLocation',
        },
        {
            title: 'Park Name',
            dataIndex: 'parkName',
            key: 'parkName',
        },
        {
            title: 'Actions',
            key:'action',
            render: (text: any, record: { name: React.ReactNode; }) => (
                <Space size="middle">
                    <span className="table-action-icons" onClick={()=>{handleActions(record,'view')}}><EyeFilled /></span>
                    <span className="table-action-icons" onClick={()=>{handleActions(record,'edit')}}><EditFilled /></span>
                </Space>
            ),
        },
    ];

    const tableProps = [
        // {
        //     key:"1",
        //     tabName:"PENDING APPROVAL",
        //     dataSource: pendingRestaurantData,
        //     columns: pendingColumns,
        //     loading: pendingLoading
        // },
        {
            key:"2",
            tabName:"LIVE RESTAURANTS",
            dataSource: liveRestaurantData,
            columns: liveColumns,
            loading: liveLoading
        }
    ];

    const approvalColumns = [
        {
            title: 'RESTAURANT DETAILS',
            dataIndex: 'restaurantDetails',
            key: 'restaurantDetails',
        },
        {
            title: 'EXISTING VALUE',
            dataIndex: 'existingValue',
            key: 'existingValue',
        },
        {
            title: 'CHANGED VALUE',
            dataIndex: 'changedValue',
            key: 'changedValue',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            let selectedOptionalParams: any = {};
            selectedRows.map((selectedRow: { existingValue: any;
                restaurantDetails: any; changedValue: any; })=>{
                    selectedOptionalParams[selectedRow.restaurantDetails] =
                        selectedRow.changedValue === 'Yes' ? true : selectedRow.changedValue === 'No' ? false :
                            selectedRow.changedValue;
                    setOptionalParams(selectedOptionalParams);
            })

        },
        getCheckboxProps: () => ({}),
    };

    const handleBack = () => {
        setView('listing');
        setRestaurantId('');
        setExistingRestaurantId('');
        setParkId('');
    }

    const handleSubmitSuccess = () => {
        fetchRestaurantsData(RestaurantService.fetchPendingRestaurantsList, setPendingRestaurantData,setPendingLoading)
        fetchRestaurantsData(RestaurantService.fetchLiveRestaurantsList, setLiveRestaurantData,setLiveLoading)
        setVisible(false);
        handleBack();
    }

    return (
        <div className="component-styles">
            <BulkUploadModal showBulkUpload={showBulkUpload}
                             setShowBulkUpload={setShowBulkUpload}
                             bulkUploadAction={bulkUploadAction}
                             setBulkUploadAction={setBulkUploadAction}
                             templateUrl={RESTAURANTS_TEMPLATE_URL}
                             validationUrl={RESTAURANTS_VALIDATE_FILE}
                             publishUrl={RESTAURANTS_PUBLISH_FILE}
                             refreshData={()=>fetchRestaurantsData(RestaurantService.fetchLiveRestaurantsList, setLiveRestaurantData,setLiveLoading)}
                             bulkUploadFor='restaurants'
            />
            <DeleteConfirmation deleteVisible={deleteVisible}
                                setDeleteVisible={setDeleteVisible}
                                text='Restaurant'
                                handleDelete={handleDelete}/>
            {loading && <CircularLoader/>}
            {view === 'listing' && !loading &&
            <>
                <Button className="add-new-ride restaurant-bulk-upload-button" onClick={()=>setShowBulkUpload(true)}>Manage Menu URL</Button>
                <Button className="add-new-ride" onClick={()=>{handleActions({restaurantId:'new'},'edit')}}>Add New Restaurant</Button>
                <TabsTable tableProps={tableProps}/>
            </>
            }
            {view === 'approval' && !loading &&
            <>
                <div className="back-button" onClick={()=>setView('listing')}><LeftSquareFilled />Back</div>
                <ApprovalView dataSource={approvalData} columns={approvalColumns}
                              rowSelection={{type: 'checkbox', ...rowSelection}}/>
                <div className="approve-actions">
                    <Button type="text" onClick={()=>setView('listing')}>Cancel</Button>
                    <Button type="primary" disabled={Object.keys(optionalParams).length ===0}
                            onClick={()=>handleOptionalParamsApprove(existingRestaurantId)}>Approve</Button>
                </div>
            </>
            }
            <RestaurantApprovalModal setVisible={setVisible}
                               visible={visible}
                               existingRestaurantId={existingRestaurantId}
                               parkId={parkId}
                               restaurantId={restaurantId}
                               optionalParams={optionalParams}
                               handleSubmitSuccess={handleSubmitSuccess}
            />
        </div>
    )
}

export default Restaurants;
