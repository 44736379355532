import axiosInstance from "../../interceptor/axiosInstance";
import {DASHBOARD_API} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import {DashboardModel} from "../../models/dashboard.model";

export default class DashboardService {

    //get crowd calendar
    static fetchDashboardData(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get(DASHBOARD_API)
            .then(response => {
                const dashboardData = deserialize(DashboardModel,response.data['data']);
                onSuccess(dashboardData);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
