
/* Auth routes */
export const AUTH = "/auth"
export const REGISTER = AUTH + "/register"
export const LOGIN = AUTH + "/login"
export const FORGOT_PASSWORD = AUTH + "/forgot-password"
export const RESET_PASSWORD = AUTH + "/reset-password"

/* Dashboard route */
export const DASHBOARD = "/dashboard";

/*Ride routes*/
export const RIDES = "/rides";
export const RIDE_DETAILS = RIDES+"/:rideId"
export const RIDE_FORM = RIDES+"/form/:rideId"

/*Restaurant routes*/
export const RESTAURANTS = "/restaurants";
export const RESTAURANT_DETAILS = RESTAURANTS+"/:restaurantId";
export const RESTAURANT_FORM = RESTAURANTS+"/form/:restaurantId";


/*Crowd calendar*/
export const CROWD_CALENDAR = "/crowd_calendar";

/*extra magic hours*/
export const EXTRA_MAGIC_HOURS = "/extra_magic_hours";
export const EVENT_DATES = EXTRA_MAGIC_HOURS+'/event_dates_management';

/*users*/
export const USERS = "/users";

/*rain gear shops*/
export const RAIN_GEAR_SHOPS = '/rain_gear_shops';

/*games*/
export const GAMES = '/games';

/*blogs*/
export const BLOGS = '/blogs';
