import React, {useState} from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import {validationSchema} from "./LoginValidation"
import { Button,Checkbox } from "antd";
import UserService from "../../../services/AuthService/auth.service";
import { withRouter } from "react-router-dom";
import '../auth.scss';
import {User} from "../../../models/user.model";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";

const loginInitialValue = {
    email: "",
    password: ""
}


const LoginForm = (props: any) => {

    const [loginLoader, setLoginLoader] = useState(false);

    const onSubmit = async(user: User) => {
        setLoginLoader(true)
        let userCredentials = {user: user};

        await UserService.loginUser(userCredentials,
        () => {
            setLoginLoader(false)
            return props.history.push(appRoutes.DASHBOARD);
        }, () => {
            setLoginLoader(false)
        }, () => {

        });
    }

    return (
            <div className="login-form">
                <div className="login-form__tag">Welcome back</div>
                <div className="login-form__title">Sign in</div>
                <Formik
                    initialValues={loginInitialValue}
                    onSubmit={onSubmit}
                    validationSchema={validationSchema}
                >
                    <Form>
                        <div className='login-form__field'>
                            <div className="label">Email</div>
                            <InputField type="email" name="email" placeholder="Enter email" />
                        </div>
                        <div className="login-form__field">
                            <div className="label">Password</div>
                            <InputField type="password" name="password" placeholder="Enter password" />
                        </div>
                        <div className="login-form__options">
                            <Checkbox className="login-form__options--checkbox">Keep me signed in</Checkbox>
                            <div onClick={()=>{props.history.push(appRoutes.FORGOT_PASSWORD)}}>Forgot password?</div>
                        </div>
                        <Button htmlType="submit" className="login-form__submit" loading={loginLoader}>Sign in</Button>
                    </Form>
                </Formik>
            </div>
    )
}

export default withRouter(LoginForm);
