import React, {useEffect, useState} from "react";
import {Button, Modal, Skeleton} from "antd";
import '../HiddenMickeyDetail/hiddenMickeyDetails.scss';
import GamesService from "../../../services/GamesService/games.service";
import {Form, Formik} from "formik";
import {constructMetaValues, fetchMetaValues} from "../../../shared/utils/fetchMeta";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import FileUpload from "../../../shared/components/FileUpload";
import RideService from "../../../services/RideService/ride.service";
import {serialize} from "serializr";
import {convertJSONToFormData} from "../../../shared/utils/jsonToFormdata";
import {HiddenMickeysModel} from "../../../models/games.model";
import ImageViewer from "../../../shared/components/ImageViewer";

const HiddenMickeyForm = (props:any) => {

    const {visible,setVisible, hiddenMickeyId, refreshData} = props;

    const [initialValues,setInitialValues] = useState<any>();
    const [parksMeta,setParksMeta] = useState<any>([]);
    const [ridesMeta,setRidesMeta] = useState<any>([]);
    const [hiddenMickeyImage,setHiddenMickeyImage] = useState<any>([]);
    const [hiddenMickey,setHiddenMickey] = useState<any>();
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        fetchMetaValues('parks',setParksMeta)
        RideService.fetchLiveRidesList(
            (rides:any)=>{
                constructMetaValues(rides,setRidesMeta)
            },
            ()=>{},
            ()=>{},
        ).then()
        if(hiddenMickeyId && hiddenMickeyId !=='new') {
            fetchHiddenMickey()
        }else{
            setFormValues({})
        }
    },[hiddenMickeyId])

    const fetchHiddenMickey = () => {
        setLoading(true)
        GamesService.fetchHiddenMickeyDetails(hiddenMickeyId,
            (hiddenMickey: any) => {
                setFormValues(hiddenMickey)
                setHiddenMickey(hiddenMickey)
            },
            () => {
            },
            () => {
                setLoading(false)
            },
        ).then()
    }

    const setFormValues = (hiddenMickey:any) => {
        setInitialValues(
            {
                parkId:hiddenMickey?.parkId?.toString(),
                rideId:hiddenMickey?.rideId?.toString(),
                geoCoordinates:(hiddenMickey?.lat || '')+(hiddenMickey?.lat ? ',' : '')+(hiddenMickey?.long || ''),
                description:hiddenMickey?.description || '',
            }
        )
    }

    const onSubmit = (hiddenMickey:any) => {
        if(hiddenMickey.geoCoordinates){
            hiddenMickey.lat = hiddenMickey.geoCoordinates.split(',')[0];
            hiddenMickey.long = hiddenMickey.geoCoordinates.split(',')[1];
        }

        let data = {hidden_mickey: serialize(HiddenMickeysModel,hiddenMickey)};

        if(hiddenMickeyImage.length > 0){
            data.hidden_mickey.image = hiddenMickeyImage.map((element:any) => element.originFileObj)[0]
        }

        let formData = convertJSONToFormData(data)

        if(hiddenMickeyId !== 'new') {
            GamesService.updateHiddenMickey(hiddenMickeyId, formData,
                () => {
                    setHiddenMickeyImage([])
                    handleClose()
                    refreshData()
                },
                () => {},
                () => {},
            ).then()
        }else{
            GamesService.createHiddenMickey(formData,
                () => {
                    setHiddenMickeyImage([])
                    handleClose()
                    refreshData()
                },
                () => {},
                () => {},
            ).then()
        }
    }

    const handleClose = () => {
        setFormValues({})
        setVisible(false)
    }

    return(
        <Modal title={null}
               centered
               onCancel={()=>handleClose()}
               visible={visible}
               footer={null}
               className='hidden-mickey__modal'
        >
            <div className='hidden-mickey__title'>Hidden Mickey</div>
            {loading && <Skeleton active/>}
            {!loading && <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                {({
                      setFieldValue
                  }) => {
                    return (
                        <Form>
                            <div className='hidden-mickey__section'>
                                <div className='hidden-mickey__form-field'>
                                    <div className='label'>Park Name</div>
                                    <DropdownField mode='single' name='parkId'
                                                   onChange={(value: any) => {
                                                       setFieldValue('parkId',value)
                                                   }}
                                                   options={parksMeta} placeholder='Select Park'
                                    />
                                </div>
                            </div>
                            <div className="hidden-mickey__section">
                                <div className='hidden-mickey__form-field'>
                                    <div className='label'>Hidden Mickey Location / Description</div>
                                    <InputField type="textarea" name="description" placeholder="Description"/>
                                </div>
                            </div>
                            <div className='hidden-mickey__section'>
                                <div className='hidden-mickey__form-field'>
                                    <div className='label'>Ride Name</div>
                                    <DropdownField mode='single' name='rideId'
                                                   onChange={(value: any) => {
                                                       setFieldValue('rideId',value)
                                                   }}
                                                   options={ridesMeta} placeholder='Select Ride'
                                    />
                                </div>
                                <div className='hidden-mickey__form-field'>
                                    <div className='label'>Geo Coordinates</div>
                                    <InputField type="text" name="geoCoordinates" placeholder="Geo Coordinates"/>
                                </div>
                            </div>
                            <div className="hidden-mickey__section">
                                <div className='hidden-mickey__form-field'>
                                    <div className='label'>Photo (Optional)</div>
                                    <div className='image-upload-container'>
                                        <FileUpload fileList={hiddenMickeyImage} setFileList={setHiddenMickeyImage} limit={1}/>
                                    </div>
                                    {hiddenMickey?.imageUrl &&
                                    <ImageViewer imageUrl={hiddenMickey?.imageUrl}
                                                 deleteService={GamesService.deleteHiddenMickeyImage}
                                                 id={hiddenMickeyId}
                                                 refreshData={fetchHiddenMickey}
                                    />
                                    }
                                </div>
                            </div>

                            <div className="form-actions">
                                <Button key="back" type="text" onClick={()=>handleClose()}>Cancel</Button>
                                <Button key="approve" type="primary" htmlType="submit"
                                    className="hidden-mickey__form-submit">Save</Button>
                            </div>
                        </Form>
                    )
                }
                }
            </Formik>}
        </Modal>
    )
}

export default HiddenMickeyForm;
