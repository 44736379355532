import axiosInstance from "../interceptor/axiosInstance";
import Notification from "../shared/components/Notification";
import { NotificationTypes } from "../enums/notificationTypes";

export default class MetaService {

    //get ride list
    static fetchMetaValues(
        metaName:string,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .get('/'+metaName)
            .then(response => {
                onSuccess(response.data[metaName]);
            })
            .catch(error => {
                Notification({
                    message: "Error",
                    description: "Unable to fetch the meta data",
                    type: NotificationTypes.ERROR
                });
                onError(error);
            })
            .finally(onFinal)
    }
}
