import React from 'react';
import { Upload } from 'antd';
import './fileUpload.scss'
import {PlusCircleOutlined} from "@ant-design/icons/lib";

const FileUpload = (props: any) => {

    const {fileList, setFileList, limit} = props;

    const handleChange = (file: any) => {setFileList(file.fileList);};

    const uploadButton = (
        <div className="upload-button">
            <PlusCircleOutlined />
            <div className="ant-upload-text">Upload photo</div>
            <div><i>(Size should be 255*525)</i></div>
        </div>
    );
    return (
        <div className="clearfix">
            <Upload
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
            >
                {fileList.length >= limit ? null : uploadButton}
            </Upload>
        </div>
    );
}

export default FileUpload;
