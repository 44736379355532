import React, {useRef} from "react";
import '../restaurants.scss';
import { Button, Modal} from "antd";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import RestaurantService from "../../../services/RestaurantService/restaurant.service";
import {serialize} from "serializr";
import {RestaurantDetailsModel} from "../../../models/restaurant.model";
import RideService from "../../../services/RideService/ride.service";

const RestaurantApprovalModal = (props: any) => {

    const {setVisible,visible,existingRestaurantId,parkId,restaurantId,
        optionalParams,handleSubmitSuccess} = props;

    const resFormikRef = useRef<any>();

    const formInitState={
        geoCoordinates:"",
    }

    const onSubmit = async(restaurantDetails: any,resetForm: Function) =>{
        if(restaurantDetails.geoCoordinates){
            restaurantDetails.lat = restaurantDetails.geoCoordinates.split(',')[0];
            restaurantDetails.long = restaurantDetails.geoCoordinates.split(',')[1];
        }
        let data = {restaurant: serialize(RestaurantDetailsModel,restaurantDetails)}
        if(!existingRestaurantId && Object.keys(optionalParams).length === 0) {
            data.restaurant.external_api_restaurant_id = restaurantId;
            data.restaurant.optional_params = false;
        }else{
            data.restaurant = {
                ...data.restaurant,
                ...optionalParams
            }
            data.restaurant.park_id=parkId;
            data.restaurant.optional_params = true;
        }

        //to remove undefined key value pairs
        Object.keys(data.restaurant).forEach(key => data.restaurant[key] === undefined && delete data.restaurant[key])

        await RestaurantService.createNewRestaurant(data,
            () => {
                handleSubmitSuccess()
                resetForm(formInitState);
            },
            () => {},
            () => {},
        )
    };

    return (
        <Modal
            title={null}
            centered
            onCancel={()=>{setVisible(false);resFormikRef.current.resetForm(formInitState)}}
            visible={visible}
            footer={null}
            className="restaurant-modal"
        >
            <Formik innerRef={resFormikRef}
                    initialValues={formInitState}
                    onSubmit={async(values,{resetForm})=>
                    {await onSubmit(values,resetForm)}}
                    enableReinitialize>
                <Form>
                    <div className="ride-form">
                        <div className="label">Geo Coordinates (lat,long)</div>
                        <InputField type="text" name="geoCoordinates" placeholder="Enter geo coordinates" />
                    </div>
                    <div className="form-actions">
                        <Button key="back" type="text" onClick={() =>{setVisible(false);
                            resFormikRef.current.resetForm(formInitState)}}>Cancel</Button>
                        <Button key="approve" type="primary" htmlType="submit">Approve</Button>
                    </div>
                </Form>
            </Formik>
        </Modal>
    )
}

export default RestaurantApprovalModal;
