import React, {useEffect, useState} from "react";
import '../users.scss';
import {Modal, Skeleton} from "antd";
import UserManagementService from "../../../services/UserService/user.service";
import CircularLoader from "../../../shared/components/CircularLoader";

const UserDetails = (props: any) => {

    const {userId,showUserDetails,setShowUserDetails} = props;

    const [userDetails,setUserDetails]= useState<any>();
    const [loading,setLoading]= useState<boolean>(false);

    useEffect(()=>{
        if(userId) {
            setLoading(true)
            UserManagementService.fetchUserDetails(userId,
                (userDetails: any) => {
                    setUserDetails(userDetails)
                },
                () => {
                },
                () => {
                    setLoading(false)
                },
            ).then()
        }
    },[userId]);

    return (
        <Modal title={null}
               centered
               onCancel={()=>setShowUserDetails(false)}
               visible={showUserDetails}
               footer={null}
        >
            {loading && <Skeleton active />}
            {!loading && <>
                <div className='modal-title'>User Details</div>
                <div className='user-details inline-fields'>
                    <div className="label">User ID</div>
                    <div className="value">{userDetails?.id ? userDetails.id : '-'}</div>
                </div>
                <div className='user-details inline-fields'>
                    <div className="label">User name</div>
                    <div className="value">{userDetails?.name ? userDetails.name : '-' }</div>
                </div>
                <div className='user-details inline-fields'>
                    <div className="label">Height</div>
                    <div className="value">{userDetails?.height ?
                        userDetails.height!== 0 ? userDetails.height+" feet" :"-" : "-" } </div>
                </div>
                <div className='user-details'>
                    <div className="label">Ride preferences</div>
                    {userDetails?.ridePreferences?.map((ridePreference: any) =>
                        <div className="user-details__ride-tags">{ridePreference.rideTypeName}</div>
                    )}
                </div>
                <div className='user-details'>
                    <div className="label">Food preferences</div>
                    {userDetails?.cuisinePreferences?.map((foodPreference: any) =>
                        <div className="user-details__food-tags">{foodPreference.cuisineName}</div>
                    )}
                </div>
                <div className='user-details'>
                    <div className="label">Mobility preferences</div>
                    {userDetails?.mobilityPreferences?.map((mobilityPreference: any) =>
                        <div className="user-details__ride-tags">{mobilityPreference.mobilityTypeName}</div>
                    )}
                </div>
                <div className='user-details'>
                    <div className="label">Number of groups</div>
                    <div className="value">{userDetails?.numberOfGroups}</div>
                </div>
                <div className='user-details'>
                    <div className="label">Pro member</div>
                    <div className="value">{userDetails?.proEnabled ? 'Yes' : 'No'}</div>
                </div>
            </>}
        </Modal>
    )
}

export default UserDetails;
