import React, {useEffect, useState} from "react";
import './dashboard.scss'
import DashboardService from "../../services/DashboardService/dashboard.service";
import CircularLoader from "../../shared/components/CircularLoader";

const Dashboard = (props: any) => {

    useEffect(()=>{
        setDashboardLoading(true)
        DashboardService.fetchDashboardData(
            (data: any)=>{
                let sampleDashboardData = [
                    {title:'Total users',count:data?.userCount},
                    {title:'Paid users',count:data?.paidUserCount},
                    {title:'Total revenue',count:'$'+ parseFloat(data?.totalRevenue).toFixed(2)},
                    {title:'Total theme parks',count:data?.parkCount},
                    {title:'Total rides',count:data?.rideCount},
                    {title:'Total restaurants',count:data?.restaurantCount},
                    {title:'Total groups added in platform',count: data?.groupCount},
                    {title:'Total trips planned',count:data?.planCount},
                    {title:'Total hidden mickeys',count:data?.hiddenMickeyCount},
                    {title:'Total jokes',count:data?.riddleCount},
                    {title:'Total trivia questions',count:data?.triviumCount},
                    {title:'Total blog posts',count:data?.blogCount},
                ];
                setDashboardData(sampleDashboardData);
            },
            ()=>{},
            ()=>{setDashboardLoading(false)}
        ).then()
    },[]);

    const [dashboardData, setDashboardData] = useState<any>([]);
    const [dashboardLoading, setDashboardLoading] = useState<boolean>(false);

    return (
        <div className="component-styles">
            {dashboardLoading &&
                <CircularLoader/>
            }

            {!dashboardLoading &&
                dashboardData?.map((cardDetail: { count: React.ReactNode; title: React.ReactNode; })=>{
                return (
                    <div className="dashboard-cards">
                        <div className="dashboard-cards__count">{cardDetail.count}</div>
                        <div className="dashboard-cards__title">{cardDetail.title}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default Dashboard;
