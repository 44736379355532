import React, {useEffect, useState} from "react";
import {Modal, Skeleton} from "antd";
import './hiddenMickeyDetails.scss';
import GamesService from "../../../services/GamesService/games.service";

const HiddenMickeyDetail = (props:any) => {

    const {visible,setVisible, hiddenMickeyId} = props;

    const [details,setDetails] = useState<any>();
    const [loading,setLoading] = useState<boolean>(false);

    useEffect(()=>{
        if(hiddenMickeyId) {
            setLoading(true)
            GamesService.fetchHiddenMickeyDetails(hiddenMickeyId,
                (hiddenMickey: any) => {
                    setDetails(hiddenMickey)
                },
                () => {
                },
                () => {
                    setLoading(false)
                },
            ).then()
        }
    },[hiddenMickeyId])

    return(
        <Modal title={null}
               centered
               onCancel={()=>setVisible(false)}
               visible={visible}
               footer={null}
               className='hidden-mickey__modal'
        >
            <div className='hidden-mickey__title'>Hidden Mickey</div>
            {loading && <Skeleton active/>}
            {!loading && <>
                <div className='hidden-mickey__section'>
                    <div>
                        <div className='label'>Park Location</div>
                        <div className='value'>{details?.parkLocation || '-'}</div>
                    </div>
                    <div>
                        <div className='label'>Park Name</div>
                        <div className='value'>{details?.parkName || '-'}</div>
                    </div>
                </div>
                <div className="hidden-mickey__section">
                    <div>
                        <div className='label'>Hidden Mickey Location / Description</div>
                        <div className='value'>{details?.description || '-'}</div>
                    </div>
                </div>
                <div className='hidden-mickey__section'>
                    <div>
                        <div className='label'>Ride Name</div>
                        <div className='value'>{details?.rideName || '-'}</div>
                    </div>
                    <div>
                        <div className='label'>Geo Coordinates</div>
                        <div className='value'>
                            {details?.lat || '-'},{details?.long || '-'}
                        </div>
                    </div>
                </div>
                <div className="hidden-mickey__section">
                    <div>
                        <div className='label'>Hidden Mickey Image</div>
                        <div className='hidden-mickey__image'>
                            <img src={details?.imageUrl} alt=''/>
                        </div>
                    </div>
                </div>
            </>}
        </Modal>
    )
}

export default HiddenMickeyDetail;
