import React, {useEffect, useState} from "react";
import './rides.scss';
import TabsTable from "../../shared/components/TabsTable";
import {Tag, Space, Button } from "antd";
import {CloseSquareOutlined, EditFilled, EyeFilled, LeftSquareFilled} from "@ant-design/icons/lib";
import ApprovalView from "../../shared/components/RideRestaurantApproval";
import {generatePath} from "react-router-dom";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import RideService from "../../services/RideService/ride.service";
import moment from "moment";
import {DeleteConfirmation} from "../../shared/components/DeleteConfirmation";
import RideApprovalModal from "./RideApprovalModal";
import CircularLoader from "../../shared/components/CircularLoader";

const Rides = (props: any) => {

    useEffect(()=>{
        // fetchRidesData(RideService.fetchPendingRidesList, setPendingRideData,setPendingLoading)
        fetchRidesData(RideService.fetchLiveRidesList, setLiveRideData,setLiveLoading)
    },[]);

    const fetchRidesData = (apiFunction: Function, stateFn: Function,loaderFn:any) => {
        loaderFn(true)
        apiFunction(
            (rideList: any) => {
                rideList.map((ride: { lastUpdatedOn: string | number | Date; }) => {
                    ride.lastUpdatedOn = moment(ride.lastUpdatedOn).format("DD/MM/YYYY");
                })
                stateFn(rideList)
            },
            () => {},
            () => {loaderFn(false)}
        )
    }

    const [pendingRideData, setPendingRideData] = useState<any>([]);
    const [liveRideData, setLiveRideData] = useState<any>([]);
    const [approvalData, setApprovalData] = useState<any>([]);
    const [view,setView] = useState('listing');
    const [visible, setVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [rideId, setRideId] = useState('');
    const [rideName, setRideName] = useState('');
    const [parkId, setParkId] = useState('');
    const [existingRideId, setExistingRideId] = useState('');
    const [optionalParams,setOptionalParams] = useState({});
    const [pendingLoading,setPendingLoading] = useState<boolean>(false);
    const [liveLoading,setLiveLoading] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false)

    const handleApprove = (record: any)=>{
        setRideId(record?.rideId);
        setParkId(record?.parkId);
        setRideName(record?.name);
        if(record?.existingRideId){
            setLoading(true)
            let data={ride:{external_api_ride_id: record?.rideId,optional_params: false}};
            RideService.updateLiveRide(record?.existingRideId, data,
                () => {
                    fetchRidesData(RideService.fetchPendingRidesList, setPendingRideData,setPendingLoading)
                    fetchRidesData(RideService.fetchLiveRidesList, setLiveRideData,setLiveLoading)
                },
                () => {},
                () => {
                    setLoading(false)
                },
            ).then()
        }else{
            setVisible(true);
        }
    }

    const handleOptionalParamsApprove = async(existingRideId:string)=>{
        if(existingRideId){
            setLoading(true)
            let data:any = {ride:optionalParams};

            //to remove undefined key value pairs
            Object.keys(data.ride).forEach(key => data.ride[key] === undefined
                                                && delete data.ride[key])

            RideService.updateLiveRide(existingRideId, data,
                () => {
                    handleSubmitSuccess()
                },
                () => {},
                () => {
                    setLoading(false)
                },
            ).then()
        }else{
            setVisible(true);
        }
    }

    const handleCompare = (record: any)=>{
        setLoading(true)
        setRideId(record.rideId);
        setExistingRideId(record.existingRideId);
         RideService.fetchRidesCompare(record.rideId,
            (ridesData: any)=>{
                setParkId(ridesData['external_api_ride']['park_id'])
                let approvalDataForCompare = [];
                let rideDetailsColumns: string[] = [];

                //get the columns
                if(Object.keys(ridesData['external_api_ride']).length > 0) {
                    rideDetailsColumns = Object.keys(ridesData['external_api_ride'])
                }

                //to hide id and update time fields from ui
                if(rideDetailsColumns.length>0){
                    rideDetailsColumns = rideDetailsColumns.filter(column => !column.includes('id'))
                    rideDetailsColumns = rideDetailsColumns.filter(column => !column.includes('time'))
                }

                let i=1;
                //data for comparison
                for(let column of rideDetailsColumns){
                    approvalDataForCompare.push(
                        {
                            key: i,
                            rideDetails: column,
                            changedValue: typeof ridesData['external_api_ride'][column] === "boolean" ? (ridesData['external_api_ride'][column] ? 'Yes' : 'No') :
                                (ridesData['external_api_ride'][column] ? ridesData['external_api_ride'][column] : '-'),
                            existingValue: ridesData['ride'] ?
                                (typeof ridesData['ride'][column] === "boolean" ? (ridesData['ride'][column] ? 'Yes' : 'No') :
                                    (ridesData['ride'][column] ? ridesData['ride'][column] : '-')):'-'
                        }
                    )
                    i++;
                }
                setApprovalData(approvalDataForCompare)
                setView('approval');
            },
            ()=>{},
            ()=>{
                setLoading(false)
            },
         ).then()
    }

    const handleDelete = () =>{
        RideService.deletePendingRide(rideId,
            ()=>{
                fetchRidesData(RideService.fetchPendingRidesList, setPendingRideData,setPendingLoading)
                setDeleteVisible(false);
                handleBack()
            },
            ()=>{},
            ()=>{}
        ).then()
    }

    const handleActions = (record: any,action: string) => {
        let baseUrl = action === 'view' ? appRoutes.RIDE_DETAILS :
            action === 'edit' ? appRoutes.RIDE_FORM : ''
        let URL = generatePath(baseUrl, {rideId: record.rideId})

        props.history.push(URL)
    }

    const handleBack = () => {
        setView('listing');
        setRideId('');
        setExistingRideId('');
        setParkId('');
        setRideName('');
    }

    const handleSubmitSuccess = () => {
        fetchRidesData(RideService.fetchPendingRidesList, setPendingRideData,setPendingLoading)
        fetchRidesData(RideService.fetchLiveRidesList, setLiveRideData,setLiveLoading)
        setVisible(false);
        handleBack();
    }

    const pendingColumns = [
        {
            title: 'Ride ID',
            dataIndex: 'rideId',
            key: 'rideId',
        },
        {
            title: 'Park Location',
            dataIndex: 'parkLocation',
            key: 'parkLocation',
        },
        {
            title: 'Park Name',
            dataIndex: 'parkName',
            key: 'parkName',
        },
        {
            title: 'Ride Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Short Name',
            dataIndex: 'shortName',
            key: 'shortName',
        },
        {
            title: 'Last updated on',
            dataIndex: 'lastUpdatedOn',
            key: 'lastUpdatedOn',
        },
        {
            title: 'Actions',
            key:'action',
            render: (text: any, record: {
                existingRideId: string;
                rideId: string;
                name: React.ReactNode; }) => (
                <Space size="middle">
                    <Button className="table-approve-button" onClick={()=>{handleApprove(record)}}>Approve</Button>
                    <span className="table-action-icons" onClick={()=>{handleCompare(record)}}><EyeFilled /></span>
                    <span className="table-action-icons" onClick={()=>{
                        setDeleteVisible(true);
                        setRideId(record.rideId)
                    }}>
                        <CloseSquareOutlined />
                    </span>
                </Space>
            ),
        },
    ];

    const liveColumns = [
        {
            title: 'Ride ID',
            dataIndex: 'rideId',
            key: 'rideId',
        },
        {
            title: 'Park Location',
            dataIndex: 'parkLocation',
            key: 'parkLocation',
        },
        {
            title: 'Park Name',
            dataIndex: 'parkName',
            key: 'parkName',
        },
        {
            title: 'Ride Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Short Name',
            dataIndex: 'shortName',
            key: 'shortName',
        },
        {
            title: 'Ride Status',
            dataIndex: 'status',
            key: 'rideStatus',
            render: (rideStatus: {} | null | undefined) => (
                    <Tag color={rideStatus === 'Enable'? 'green':'volcano'}>
                        {rideStatus}
                    </Tag>
            ),
        },
        {
            title: 'Actions',
            key:'action',
            render: (text: any, record: { name: React.ReactNode; }) => (
                <Space size="middle">
                    <span className="table-action-icons" onClick={()=>{handleActions(record,'view')}}><EyeFilled /></span>
                    <span className="table-action-icons" onClick={()=>{handleActions(record,'edit')}}><EditFilled /></span>
                </Space>
            ),
        },
    ];

    const tableProps = [
        // {
        //     key:"1",
        //     tabName:"PENDING APPROVAL",
        //     dataSource: pendingRideData,
        //     columns: pendingColumns,
        //     loading: pendingLoading
        // },
        {
            key:"2",
            tabName:"LIVE RIDES",
            dataSource: liveRideData,
            columns: liveColumns,
            loading: liveLoading
        }
    ];

    const approvalColumns = [
        {
            title: 'RIDE DETAILS',
            dataIndex: 'rideDetails',
            key: 'rideDetails',
        },
        {
            title: 'EXISTING VALUE',
            dataIndex: 'existingValue',
            key: 'existingValue',
        },
        {
            title: 'CHANGED VALUE',
            dataIndex: 'changedValue',
            key: 'changedValue',
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            let selectedOptionalParams: any = {};
             selectedRows.map((selectedRow: { existingValue: any;
                 rideDetails: any; changedValue: any; })=>{
                     selectedOptionalParams[selectedRow.rideDetails] =
                         selectedRow.changedValue === 'Yes' ? true : selectedRow.changedValue === 'No' ? false :
                             selectedRow.changedValue;
                     setOptionalParams(selectedOptionalParams);
            })
        },
        getCheckboxProps: () => {},
    };


    return (
        <div className="component-styles">
            <DeleteConfirmation deleteVisible={deleteVisible}
                                setDeleteVisible={setDeleteVisible}
                                text='Ride'
                                handleDelete={handleDelete}/>
            {loading && <CircularLoader/>}
            {view === 'listing' && !loading &&
                <>
                    <Button className="add-new-ride" onClick={()=>{
                        handleActions({rideId:'new'},'edit')}
                    }>Add New Ride</Button>
                    <TabsTable tableProps={tableProps}/>
                </>
            }
            {view === 'approval' && !loading &&
                <>
                    <div className="back-button" onClick={()=>handleBack()}><LeftSquareFilled />Back</div>
                    <ApprovalView dataSource={approvalData} columns={approvalColumns}
                                  rowSelection={{type: 'checkbox', ...rowSelection}}/>
                    <div className="approve-actions">
                        <Button type="text" onClick={()=>setView('listing')}>Cancel</Button>
                        <Button type="primary" disabled={Object.keys(optionalParams).length ===0}
                                onClick={()=>handleOptionalParamsApprove(existingRideId)}
                        >Approve</Button>
                    </div>
                </>
            }
            <RideApprovalModal setVisible={setVisible}
                               visible={visible}
                               existingRideId={existingRideId}
                               parkId={parkId}
                               rideId={rideId}
                               rideName={rideName}
                               optionalParams={optionalParams}
                               handleSubmitSuccess={handleSubmitSuccess}
            />
        </div>
    )
}

export default Rides;
