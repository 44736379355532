import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as routes from "../../routes/routeConstants/appRoutes";
import LoginForm from "../../views/Auth/LoginForm";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";

const authRouter = () => {
    return (
        <Switch>
            <Redirect exact strict from={routes.AUTH} to={routes.LOGIN} />
            <Route exact path={routes.LOGIN} component={() => <LoginForm />} />
            <Route exact path={routes.FORGOT_PASSWORD} component={() => <ForgotPassword />} />
            <Route exact path={routes.RESET_PASSWORD} component={() => <ResetPassword />} />
        </Switch>
    )
}

export default authRouter;
