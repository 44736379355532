import React, {useEffect, useState} from "react";
import '../games.scss';
import {Button, Space, Table} from "antd";
import {EditFilled, EyeFilled, CloseSquareOutlined} from "@ant-design/icons";
import GamesService from "../../../services/GamesService/games.service";
import HiddenMickeyDetail from "../HiddenMickeyDetail";
import HiddenMickeyForm from "../HiddenMickeyForm";
import {DeleteConfirmation} from "../../../shared/components/DeleteConfirmation";

const HiddenMickeyList = (props:any) => {

    useEffect(()=>{
        refreshData()
    },[])

    const refreshData = () => {
        setTableLoading(true)
        GamesService.fetchHiddenMickeys(
            (response:any)=>{
                setData(response)
            },
            ()=>{},
            ()=>{
                setTableLoading(false)
            },
        ).then()
    }

    const [data,setData] = useState<any>([]);
    const [hiddenMickeyId,setHiddenMickeyId] = useState<string>('');
    const [showVisible,setShowVisible] = useState<boolean>(false);
    const [editVisible,setEditVisible] = useState<boolean>(false);
    const [deleteVisible,setDeleteVisible] = useState<boolean>(false);
    const [tableLoading,setTableLoading] = useState<boolean>(false);

    const columns = [
        {
            title: 'Park location',
            dataIndex: 'parkLocation',
            key: 'parkLocation',
        },
        {
            title: 'Park name',
            dataIndex: 'parkName',
            key: 'parkName',
        },
        {
            title: 'Ride Name',
            key: 'rideName',
            render: (text: any, record: any ) => (
                <Space size="middle">
                    <span>{record?.rideName || 'Not associated'}</span>
                </Space>
            ),
        },
        {
            title: 'Geo Coordinates(lat,long)',
            key: 'geoCoordinates',
            render: (text: any, record: any ) => (
                <Space size="middle">
                    <span>{record.lat+','+record.long}</span>
                </Space>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <span className="table-action-icons" onClick={()=>{
                        handleShowHiddenMickey(record.id)
                    }}>
                        <EyeFilled />
                    </span>
                    <span className="table-action-icons" onClick={()=>{
                        handleEditHiddenMickey(record.id)
                    }}>
                        <EditFilled />
                    </span>
                    <span className="table-action-icons" onClick={()=>{
                        handleDeleteHiddenMickey(record.id)
                    }}>
                        <CloseSquareOutlined />
                    </span>
                </Space>
            ),
        },
    ];

    const handleShowHiddenMickey = (id:any) =>{
        setHiddenMickeyId(id)
        setShowVisible(true)
    }

    const handleEditHiddenMickey = (id:any) =>{
        setHiddenMickeyId(id)
        setEditVisible(true)
    }

    const handleDeleteHiddenMickey = (id:any) => {
        setHiddenMickeyId(id)
        setDeleteVisible(true)
    }

    const handleDelete = () => {
            GamesService.deleteHiddenMickey(hiddenMickeyId,
                ()=>{
                    refreshData()
                    setDeleteVisible(false)
                    setHiddenMickeyId('')
                },
                ()=>{},
                ()=>{},
            ).then()
    }

    return(
        <>
            <Button type='primary' className='game-button' onClick={()=>{
                handleEditHiddenMickey('new')
            }}>New Hidden Mickey</Button>
            <Table dataSource={data} columns={columns} loading={tableLoading}/>
            {showVisible && <HiddenMickeyDetail visible={showVisible} setVisible={setShowVisible}
                hiddenMickeyId={hiddenMickeyId}/>}
            {editVisible &&<HiddenMickeyForm visible={editVisible} setVisible={setEditVisible}
                hiddenMickeyId={hiddenMickeyId} refreshData={refreshData}/>}
            <DeleteConfirmation deleteVisible={deleteVisible}
                                setDeleteVisible={setDeleteVisible}
                                text='Hidden Mickey'
                                handleDelete={handleDelete}/>
        </>
    )
}

export default HiddenMickeyList;
