import {Button, Modal, Upload, message, Progress} from "antd";
import React, {useEffect, useState} from "react";
import {CloseCircleFilled, DownloadOutlined} from "@ant-design/icons/lib";
import './bulkUploadModal.scss';
// @ts-ignore
import {saveAs} from "file-saver";
import {getHeaders} from "../../../interceptor/axiosInstance";
import {ACTION_CABLE} from "../../../routes/routeConstants/apiRoutes";
import ActionCable from "actioncable";
import BulkUploadService from "../../../services/BulkUploadService/bulkUpload.service";
import type { UploadProps } from "antd/es/upload";
import { RcFile } from 'antd/lib/upload/interface';
import { UploadFile } from 'antd/lib/upload/interface';




const { Dragger } = Upload;

export const BulkUploadModal = (props:any) =>{
  interface FileInfo {
    name?: string;
    uid?: string;
    status?: string;
    originFileObj?: File;

  }

    const {showBulkUpload,setShowBulkUpload, bulkUploadAction, setBulkUploadAction,
         templateUrl, validationUrl, publishUrl, refreshData, bulkUploadFor} = props;
         
         const[jobId,setJobId]=useState<number>()

    let user:any;
    let headers = getHeaders();
    let queryString = Object.keys(headers).map(key => key + '=' + headers[key]).join('&');
    const URL =  ACTION_CABLE + "?" + queryString;
    const cable = ActionCable.createConsumer(URL);
    const connected = () =>{console.log("connected")};

    const disconnected = () => {console.log("disconnected")};

    const rejected = () => {console.log("rejected")};

    const received = (data:any) => {
        if(data.progress){
            setProgress(data.progress)
        }
        if(data.errors.length > 0){
            setErrorDetails(data.errors);
            setBulkUploadAction('error');
        }
    };

    if (localStorage.getItem('user')) {
        user = JSON.parse(localStorage.getItem('user') || '');
    }

    useEffect(()=> {
            cable.subscriptions.create({
                channel: "WebNotificationsChannel",
                room: `job_notification_${user.userDetails.id}`
            }, {
                connected: connected,
                disconnected: disconnected,
                received: received,
                rejected: rejected
            })
        },[]
    );

    const [fileList, setFileList] = useState<RcFile[]>([]); 
    const [progress,setProgress] = useState(0);
    const [errorDetails,setErrorDetails] = useState([]);
    const [userAction,setUserAction] = useState('');

    const draggerProps: UploadProps = {
      name: 'file',
      multiple: false,
      accept: '.xlsx',
      beforeUpload: (file) => {
        return false;
      },
      onChange(info) {
        setFileList(info?.fileList.map(file => file as RcFile));
  
          const file = info.file as FileInfo;
  
          if (file.status !== 'uploading') {
              let formData = new FormData();
              if (file.originFileObj) {
                  formData.append(`${bulkUploadFor}[file]`, file.originFileObj);
  
              }
          }
      },
  };
  

    const handleTemplateDownload = () =>{
        BulkUploadService.fetchTemplate(templateUrl,
            (response: any)=>{
                saveAs(response,`${bulkUploadFor}_bulk_upload_template.xlsx`);
            },
            ()=>{},
            ()=>{},

        ).then()
    };

    const handleFileValidate = () => {
      setBulkUploadAction('progress');
      setUserAction('validation');
  
      const formData = new FormData();
      
      const file = fileList[0];
      if (file) {
          const uploadedFile = file as UploadFile;
          const originFile = uploadedFile.originFileObj;
          if (originFile) {
              formData.append(`${bulkUploadFor}[file]`, originFile as Blob);
          }
  
          BulkUploadService.validateFile(
              validationUrl,
              formData,
              (data:any) => {setJobId(data?.job_id)},
              () => {},
              () => {},
          ).then();
      }
  };

    const handlePublish = () =>{
        setUserAction('publish');
        BulkUploadService.publishFile(publishUrl,
            ()=>{
                setErrorDetails([]);
                setShowBulkUpload(false);
                setBulkUploadAction('upload');
                setProgress(0);
            },
            ()=>{
                setShowBulkUpload(false);
            },
            ()=>{},
            jobId
            
        ).then()
    }

    const handleClose = () => {
        setShowBulkUpload(false);
        setFileList([])
        setBulkUploadAction('upload');
        setProgress(0);
        setErrorDetails([]);
        if(userAction==='publish'){
            refreshData();
            setUserAction('')
        }
    }

    return(
        <Modal
            title={null}
            centered
            onCancel={()=>{handleClose()}}
            visible={showBulkUpload}
            footer={null}
            destroyOnClose
            className='bulk-upload-modal'
        >
            {bulkUploadAction === 'upload' &&
                <div className='bulk-upload-modal__content'>
                    <div className="bulk-upload-modal__title">CHOOSE FILE</div>
                    <div className="bulk-upload-modal__template"
                     onClick={()=>handleTemplateDownload()}>Click here to download template<DownloadOutlined /></div>
                    <div className="bulk-upload-modal__file-selection">
                        <Dragger {...draggerProps}>
                            <p className="ant-upload-text">Drag and Drop file</p>
                        </Dragger>
                    </div>
                    <div className="bulk-upload-modal__actions">
                        <Button type='primary' className='bulk-upload-modal__actions--validate'
                         onClick={()=>{handleFileValidate()}}>Validate</Button>
                    </div>
                </div>
            }
            {bulkUploadAction === 'progress' &&
                <div className='bulk-upload-modal__content'>
                    {userAction === 'validation' && <div className="bulk-upload-modal__title">
                         FILE PROCESS
                    </div>}
                    {userAction === 'publish' && <div className="bulk-upload-modal__title">
                         FILE PUBLISH
                    </div>}
                    <div className="bulk-upload-modal__progress">
                        <div className="bulk-upload-modal__progress--text">{progress <=100 ? progress+'%' : 'Completed'}</div>
                        <div className="bulk-upload-modal__progress--bar">
                            <Progress percent={progress} status={progress >= 100 ? "success" : "active"} showInfo={false}/>
                        </div>
                    </div>
                    {userAction === 'validation' && <div className="bulk-upload-modal__actions">
                        <Button key="cancel" type='text' className='bulk-upload-modal__actions--cancel'
                         onClick={()=>{handleClose()}}>Cancel</Button>
                        <Button key="approve" type='primary' className='bulk-upload-modal__actions--validate'
                            onClick={()=>{handlePublish()}}>Publish</Button>
                    </div>}
                </div>
            }
            {bulkUploadAction === 'error' &&
                <div className='bulk-upload-modal__content'>
                    <div className="bulk-upload-modal__title">{errorDetails?.length} errors found</div>
                    <div className="bulk-upload-modal__errors">
                        {errorDetails?.map((error:string)=>
                        <div className="bulk-upload-modal__errors--card">
                            <CloseCircleFilled />{error}
                        </div>
                        )}
                    </div>
                </div>
            }
        </Modal>
    )}
