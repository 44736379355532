import axiosInstance from "../../interceptor/axiosInstance";
import {RESET_PASSWORD, USER_LOGIN, USER_LOGOUT} from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { User } from "../../models/user.model";
import { store } from "../../store";
import {AUTHENTICATED, UNAUTHENTICATED} from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class UserService {

    //user login
    static loginUser(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(USER_LOGIN, data)
            .then(response => {
                const userDetails = deserialize(User, response.data["user"]);

                let user ={
                    userDetails: userDetails,
                    adminAuthToken: response.headers['access-token'],
                    client: response.headers['client'],
                    uid: response.headers['uid'],
                    id: response.headers['id']
                };

                if(user) {
                    localStorage.setItem('user', JSON.stringify(user));
                }

                store.dispatch({
                    type: AUTHENTICATED,
                    payload: {
                        authenticated: true,
                        user: userDetails
                    }
                })
                Notification({ message: "Login", description: "Logged in successfully", type: NotificationTypes.SUCCESS });
                onSuccess(userDetails);
            })
            .catch(error => {
                Notification({ message: "Login failed", description: "incorrect email or password",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //user logout
    static logoutUser(
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .delete(USER_LOGOUT)
            .then(response => {
                store.dispatch({
                    type: UNAUTHENTICATED,
                    payload: {
                        authenticated: false,
                        user: null
                    }
                })

                localStorage.removeItem('user');

                Notification({ message: "Logout", description: "user loggedout successfully", type: NotificationTypes.SUCCESS })
                onSuccess(response)
            })
            .catch(error => {
                Notification({ message: "Logout failed", description: "Unable to logout. Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //user forgot password
    static submitEmailForOtp(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .post(RESET_PASSWORD, data)
            .then(response => {
                onSuccess(response);
            })
            .catch(error => {
                Notification({ message: "Submit failed", description: "Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }

    //user reset password
    static resetPassword(
        data: Object,
        onSuccess: Function,
        onError: Function,
        onFinal: () => void
    ) {
        return axiosInstance
            .put(RESET_PASSWORD, data)
            .then(response => {
                onSuccess(response);
            })
            .catch(error => {
                Notification({ message: "Submit failed", description: "Please try again",type: NotificationTypes.ERROR });
                onError(error);
            })
            .finally(onFinal)
    }
}
