import React from "react";
import './showDetail.scss';

const ShowDetails = (props: any) => {
    const {details} = props;

    return(
        details.map((detail: any,i:number)=>{
            return(
                <div className={detail.className ? 'show-detail ' + detail.className : 'show-detail'}
                     key={i}
                >
                    <div className="label">{detail.label}</div>
                    <div className="value"
                         onClick={()=>{
                             if(detail.type === 'url') window.open(`${detail.value}`,'_blank')
                         }}
                    >
                        {detail.value || '-'}
                    </div>
                </div>
            )
        })
    )
}

export default ShowDetails;
