import React, {useEffect, useState} from "react";
import './restaurantDetails.scss';
import {LeftSquareFilled} from "@ant-design/icons/lib";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import ShowDetails from "../../../shared/components/ShowDetails";
import ShowTags from "../../../shared/components/ShowTags";
import {withRouter} from "react-router";
import {Rate} from "antd";
import RestaurantService from "../../../services/RestaurantService/restaurant.service";
import moment from "moment";
import ridePlaceholder from "../../../assets/ride-placeholder.jpg";
import CircularLoader from "../../../shared/components/CircularLoader";
import RideImages from "../../RidesList/RideImages";
import AttractionImages from "../../RidesList/RideImages";

const RestaurantDetails = (props: any) => {

    const {restaurantId}  = props.match.params;

    useEffect(()=>{
        fetchRestaurantDetails();
    },[restaurantId]);

    const fetchRestaurantDetails = () => {
        setLoading(true);
        RestaurantService.fetchRestaurantDetails(restaurantId,
            (restaurantValues: any) => {
                setRestaurantValues(restaurantValues);
            },
            () => {
            },
            () => {
                setLoading(false)
            }
        ).then()
    }

    const [restaurantValues, setRestaurantValues] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const restaurantDetails=[
        {label:'Park location', value:restaurantValues?.parkLocation},
        {label:'Park name', value:restaurantValues?.parkName},
        {label:'Restaurant name', value:restaurantValues?.name},
        {label:'Expected cost', value:restaurantValues?.costCode ?
                (restaurantValues.costCode  === 'expensive' ? "$$$" :
                    restaurantValues.costCode  === 'moderate' ? "$$" :
                        restaurantValues.costCode  === 'inexpensive' ? "$" : '-') :'-'},
        {label:'Star Rating', value:<Rate disabled allowHalf value={restaurantValues?.overallRating}/>},
        {label:'Bar', value: restaurantValues?.bar ? 'Yes' : 'No'},
        {label:'Wine', value:restaurantValues?.wineList ? 'Yes' : 'No'},
        {label:'Updated at', value:moment(restaurantValues?.updatedTime).format('DD/MM/YYYY')},
        {label:'Notable snacks', value:restaurantValues?.notableSnack},
        {label:'Geo Coordinates', value:restaurantValues?.lat+','+restaurantValues?.long},
        {label:'Breakfast hours', value:restaurantValues?.breakfastHours},
        {label:'Lunch hours', value:restaurantValues?.lunchHours},
        {label:'Dinner hours', value:restaurantValues?.dinnerHours},
        {label:'Quick service', value:restaurantValues?.isQuickServiceAvailable ? 'Yes' : 'No'},
        {label:'Breakfast menu url', className:'show-textarea', value: restaurantValues?.breakfastUrl, type: 'url'},
        {label:'Brunch menu url', className:'show-textarea', value: restaurantValues?.brunchUrl, type: 'url'},
        {label:'Lunch menu url', className:'show-textarea', value: restaurantValues?.lunchUrl, type: 'url'},
        {label:'Snacks menu url', className:'show-textarea', value: restaurantValues?.snacksUrl, type: 'url'},
        {label:'Dinner menu url', className:'show-textarea', value: restaurantValues?.dinnerUrl, type: 'url'},
        {label:'Other menu url', className:'show-textarea', value: restaurantValues?.otherMenuUrl, type: 'url'},
        {label:'Perma link', className:'show-textarea', value:restaurantValues?.permalink === 'undefined' ? '-' : restaurantValues?.permalink, type: 'url'},
        {label:'Selection', className:'show-textarea', value: restaurantValues?.selection},
        {label:'Setting atmosphere', className:'show-textarea', value: restaurantValues?.settingAtmosphere},
        {label:'House specialities', className:'show-textarea', value: restaurantValues?.houseSpecialities}
    ];

    const cuisineType = restaurantValues?.restaurantCuisineMaps?.map((item:any) => item.cuisineName)
        || [];


    return(
        <div className="component-styles">
            <div className="back-button" onClick={()=>props.history.push(appRoutes.RESTAURANTS)}><LeftSquareFilled />Back</div>
            {loading && <CircularLoader />}
            {!loading && <div className="ride-details">
                <ShowDetails details={restaurantDetails}/>

                <AttractionImages attractionValues={restaurantValues}
                                  labelText={'Photos for restaurant'}
                                  refreshAttraction={fetchRestaurantDetails}
                />

                <ShowTags title="Cuisine type" tags={cuisineType}/>
                <div className='near-by-places-view'>
                    <div className="label">Near by places</div>
                    <div className='value'>{restaurantValues?.nearByPlaces}</div>
                </div>
            </div>}
        </div>
    )
}

export default withRouter(RestaurantDetails);
