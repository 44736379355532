import React, {useEffect, useState} from "react";
import './extraMagicHours.scss';
import TabsTable from "../../shared/components/TabsTable";
import {Button, Space} from "antd";
import {CalendarOutlined, DeleteFilled} from "@ant-design/icons/lib";
import ExtraMagicHoursService from "../../services/ExtraMagicHoursService/extraMagicHours.service";
import {DeleteConfirmation} from "../../shared/components/DeleteConfirmation";
import {BulkUploadModal} from "../../shared/components/BulkUploadModal";
import * as appRoutes from '../../routes/routeConstants/appRoutes';
import {EMH_PUBLISH_FILE, EMH_TEMPLATE_URL, EMH_VALIDATE_FILE} from "../../routes/routeConstants/apiRoutes";

const ExtraMagicHours = (props: any) => {

    useEffect(()=>{
        getData();
    },[]);

    const getData = () =>{
        setTableLoading(true)
        ExtraMagicHoursService.fetchExtraMagicHours(
            (response: any)=>{
                setData(response);
            },
            ()=>{},
            ()=>{
                setTableLoading(false)
            },
        ).then()
    }

    const [data,setData] = useState();
    const [deleteVisible,setDeleteVisible]=useState(false);
    const [id,setId]=useState('');
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [bulkUploadAction, setBulkUploadAction] = useState('upload');
    const [tableLoading, setTableLoading] = useState<boolean>(false);

    const handleDelete = () =>{
        ExtraMagicHoursService.deleteExtraMagicHour(id,
            async()=>{
                await getData();
                setId('');
                setDeleteVisible(false)
            },
            ()=>{},
            ()=>{},
        ).then()
    }

    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Location',
            dataIndex: 'parkLocation',
            key: 'parkLocation',
        },
        {
            title: 'Park Name',
            dataIndex: 'parkName',
            key: 'parkName',
        },
        {
            title: 'Morning Hours',
            dataIndex: 'morningHours',
            key: 'morningHours',
        },
        {
            title: 'Evening Hours',
            dataIndex: 'eveningHours',
            key: 'eveningHours',
        },
        {
            title: 'Action',
            key:'action',
            render: (text: any, record: { id: string; name: React.ReactNode; }) => (
                <Space size="middle">
                    <span className="table-action-icons" onClick={()=>{
                        setDeleteVisible(true)
                        setId(record.id)
                    }}><DeleteFilled /></span>
                </Space>
            ),
        },
    ];


    const tableProps = [
        {
            key:"1",
            tabName:"EXTRA MAGIC HOURS",
            dataSource: data,
            columns: columns,
            loading: tableLoading
        }
    ];


    return (
        <div className="component-styles">
            <DeleteConfirmation deleteVisible={deleteVisible}
                                setDeleteVisible={setDeleteVisible}
                                text='EMH'
                                handleDelete={handleDelete}/>
            <div className="dates-button">
                <Button className="dates-button__christmas" onClick={()=>{
                    props.history.push(appRoutes.EVENT_DATES, 'christmas')}}
                >
                    <CalendarOutlined /> Christmas Dates
                </Button>
                <Button className="dates-button__halloween" onClick={()=>{
                    props.history.push(appRoutes.EVENT_DATES, 'halloween')}}
                >
                    <CalendarOutlined /> Halloween Dates
                </Button>
            </div>
            <Button className="add-new-ride" onClick={()=>setShowBulkUpload(true)}>Bulk upload</Button>
            <TabsTable tableProps={tableProps}/>
            <BulkUploadModal showBulkUpload={showBulkUpload}
                             setShowBulkUpload={setShowBulkUpload}
                             bulkUploadAction={bulkUploadAction}
                             setBulkUploadAction={setBulkUploadAction}
                             templateUrl={EMH_TEMPLATE_URL}
                             validationUrl={EMH_VALIDATE_FILE}
                             publishUrl={EMH_PUBLISH_FILE}
                             refreshData={()=>getData()}
                             bulkUploadFor='extra_magic_hours'
            />
        </div>
    )
}

export default ExtraMagicHours;
