import React, {useEffect, useState} from "react";
import './rainGearShops.scss';
import TabsTable from "../../shared/components/TabsTable";
import {Button, Space, Tag} from "antd";
import {EditFilled, EyeFilled, CloseSquareOutlined} from "@ant-design/icons/lib";
import RainGearShopService from "../../services/RainGearShopService/rainGearShop.service";
import RainGearShopDetail from "./RainGearShopDetail";
import {DeleteConfirmation} from "../../shared/components/DeleteConfirmation";

const RainGearShops = (props: any) => {

    useEffect(()=>{
        getRainGearShopsList()
    },[]);

    const getRainGearShopsList = () => {
        setTableLoading(true)
        RainGearShopService.fetchRainGearShops(
            (shopsList: any)=>{
                setData(shopsList)
            },
            ()=>{},
            ()=>{
                setTableLoading(false)
            }
        ).then()
    }

    const[data,setData]= useState<any>();
    const[action,setAction]= useState('');
    const[visible,setVisible]= useState(false);
    const[shopId,setShopId]= useState('');
    const[tableLoading,setTableLoading]= useState<boolean>(false);
    const[deleteVisible,setDeleteVisible]= useState<boolean>(false);

    const handleActions = (recordId:any, action:string) =>{
        setAction(action)
        setVisible(true)
        setShopId(recordId)
    };

    const handleClose = () =>{
        getRainGearShopsList()
        setShopId('');
        setVisible(false);
        setAction('');
    }

    const columns = [
        {
            title: 'Park location',
            dataIndex: 'parkLocation',
            key: 'parkLocation',
        },
        {
            title: 'Park name',
            dataIndex: 'parkName',
            key: 'parkName',
        },
        {
            title: 'Shop name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Shop status',
            dataIndex: 'status',
            key: 'status',
            render: (status: {} | null | undefined) => (
                <Tag color={status === 'Active'? 'green':'volcano'}>
                    {status}
                </Tag>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <span className="table-action-icons" onClick={()=>handleActions(record.id,'view')}><EyeFilled /></span>
                    <span className="table-action-icons" onClick={()=>handleActions(record.id,'edit')}><EditFilled /></span>
                    <span className="table-action-icons" onClick={()=>{setShopId(record.id);setDeleteVisible(true)}}><CloseSquareOutlined /></span>
                </Space>
            ),
        },
    ];

    const tableProps = [
        {
            key:"1",
            tabName:"RAIN GEAR SHOPS",
            dataSource: data,
            columns: columns,
            loading: tableLoading
        }
    ];

    const handleDelete = () => {
        RainGearShopService.deleteRainGearShop(shopId,
            ()=>{
                getRainGearShopsList()
                setShopId('')
                setDeleteVisible(false)
            },
            ()=>{},
            ()=>{},
        ).then()
    }

    return (
        <div className="component-styles">
            <Button className="add-new-ride" onClick={()=>handleActions(null,'edit')}>Add shop</Button>
            <TabsTable tableProps={tableProps}/>
            <RainGearShopDetail handleClose={handleClose}
                                visible={visible}
                                setVisible={setVisible}
                                action={action}
                                shopId={shopId}
            />
            <DeleteConfirmation deleteVisible={deleteVisible}
                                setDeleteVisible={setDeleteVisible}
                                text='rain gear shop'
                                handleDelete={handleDelete}
            />
        </div>
    )
}

export default RainGearShops;
