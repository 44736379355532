import React, {useEffect, useState} from "react";
import './blogs.scss';
import TabsTable from "../../shared/components/TabsTable";
import {Button, Space, Tag} from "antd";
import moment from 'moment';
import {CloseSquareOutlined, EditFilled} from "@ant-design/icons";
import BlogsService from "../../services/BlogsService/blogs.service";
import BlogsForm from "./BlogsForm";
import {DeleteConfirmation} from "../../shared/components/DeleteConfirmation";
import Search from "antd/es/input/Search";

const Blogs = () => {

    useEffect(()=>{
        refreshBlogs('')
    },[]);

    const refreshBlogs = (searchString:any) =>{
        setBlogId('')
        setTableLoading(true)
        BlogsService.fetchBlogs(searchString,
            (blogs:any)=>{
                setBlogsData(blogs)
            },
            ()=>{},
            ()=>{
                setTableLoading(false)
            },
        ).then()
    }

    const [blogsData,setBlogsData] = useState<any>([]);
    const [blogId,setBlogId] = useState<any>('');
    const [editVisible,setEditVisible] = useState<boolean>(false);
    const [deleteVisible,setDeleteVisible] = useState<boolean>(false);
    const [tableLoading,setTableLoading] = useState<boolean>(false);

    const blogsColumns = [
        {
            title: 'Blog title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Blog type/tags',
            key: 'tags',
            render: (text: any, record: any) => (
                <Space size="middle">
                    {record.tags.map((tag:any) =>tag).join(', ')}
                </Space>
            ),
        },
        {
            title: 'Author',
            dataIndex: 'author',
            key: 'author',
        },
        {
            title: 'Date',
            key: 'createdAt',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <span>{moment(record.createdAt).format('DD/MM/YYYY')}</span>
                </Space>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text: any) => (
                <Tag color={text === 'Published'? 'green':'volcano'}>
                    {text}
                </Tag>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <span className="table-action-icons">
                        <EditFilled onClick={()=>handleBlogAction(record.id)}/>
                    </span>
                    <span className="table-action-icons">
                        <CloseSquareOutlined onClick={()=>{setBlogId(record.id);setDeleteVisible(true)}} />
                    </span>
                </Space>
            ),
        },
    ];

    const tableProps = [
        {
            key:"1",
            tabName:"BLOGS",
            dataSource: blogsData,
            columns: blogsColumns,
            loading: tableLoading
        }
    ];

    const handleBlogAction = (blogId:any) =>{
        setBlogId(blogId)
        setEditVisible(true)
    }

    const handleDelete = () => {
        BlogsService.deleteBlog(blogId,
            ()=>{
                setDeleteVisible(false)
                setBlogId('')
                refreshBlogs('')
            },
            ()=>{},
            ()=>{},
        ).then()
    }

    return (
        <div className="component-styles">
            <Search className='blog-search' placeholder="Input search text" enterButton
                    onSearch={(value:string) => refreshBlogs(value)} />
            <Button className="add-new-ride" onClick={()=>{
                handleBlogAction('new')
            }}>Add new blog</Button>
            <TabsTable tableProps={tableProps}/>
            <BlogsForm visible={editVisible}
                       setVisible={setEditVisible}
                       blogId={blogId}
                       refreshData={refreshBlogs}
            />
            <DeleteConfirmation deleteVisible={deleteVisible}
                                setDeleteVisible={setDeleteVisible}
                                text='blog'
                                handleDelete={handleDelete}
            />
        </div>
    )
}

export default Blogs;
