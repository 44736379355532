import {serializable, alias, primitive} from 'serializr';

export class CrowdCalendarList {
    @serializable(alias('id', primitive()))
    id?: string;
    @serializable(alias('park_name', primitive()))
    parkName?: string;
    @serializable(alias('location_name', primitive()))
    parkLocation?: string;
    @serializable(alias('date', primitive()))
    date?: string;
    @serializable(alias('park_timing', primitive()))
    parkTiming?: string;
    @serializable(alias('expectation_string', primitive()))
    expectation?: string;
}
