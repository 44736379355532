import React, {useEffect, useState} from "react";
import '../RideDetails/rideDetail.scss'
import {LeftSquareFilled} from "@ant-design/icons/lib";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import {withRouter} from "react-router";
import {Formik, Form} from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import {Button} from "antd";
import FileUpload from "../../../shared/components/FileUpload";
import RideService from "../../../services/RideService/ride.service";
import moment from "moment";
import {RideDetailsModel} from "../../../models/ride.model";
import {serialize} from "serializr";
import {generatePath} from "react-router-dom";
import {fetchMetaValues} from "../../../shared/utils/fetchMeta";
import {convertJSONToFormData} from "../../../shared/utils/jsonToFormdata";
import CircularLoader from "../../../shared/components/CircularLoader";
import AttractionImages from "../RideImages";
import ImageViewer from "../../../shared/components/ImageViewer";


const RideForm = (props: any) => {

    let {rideId}  = props.match.params;

    useEffect(()=>{
        fetchMetaValues("ride_types",setRideTypesMeta)
        fetchMetaValues("parks",setParksMeta)
        fetchMetaValues("themes",setThemesMeta)
        window.scroll(0,0)
        if(rideId !== 'new') {
            setLoading(true)
            fetchRideDetails();
        }else{
            setFormValues({})
        }
    },[rideId]);
    
    const handleThemeParkRides=(parkId?:number)=>{
        RideService.fetchThemeParkRides(String(parkId),
            (response:any)=>{
                setThemeParksRideMeta(
                    response.map((obj: { name: any; id: any; }) => {
                        return {
                            value:obj.id.toString(),
                            label:obj.name
                        }
                    })
                )
            },
            ()=>{},
            ()=>{},
        ).then()
    }

    const fetchRideDetails = () => {
        RideService.fetchRideDetails(rideId,
            (rideValues: any) => {
                setRideValues(rideValues);
                setFormValues(rideValues);
                handleThemeParkRides(rideValues?.parkId)
             
            },
            () => {
            },
            () => {
                setLoading(false)
            }
        ).then()
    }

    const [rideValues, setRideValues] = useState<any>();
    const [rideTypesMeta, setRideTypesMeta] = useState([]);
    const [parksMeta, setParksMeta] = useState([]);
    const [themesMeta, setThemesMeta] = useState([]);
    const [themeParksRideMeta, setThemeParksRideMeta] = useState([]);
    const [initialValues, setInitialValues] = useState<any>();
    const [photoPass, setPhotoPass] = useState<any>([]);
    const [ridePhotos, setRidePhotos] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = async(rideDetails: any) => {
        if(rideDetails.geoCoordinates){
            rideDetails.lat = rideDetails.geoCoordinates.split(',')[0];
            rideDetails.long = rideDetails.geoCoordinates.split(',')[1];
        }


        let data = {ride: serialize(RideDetailsModel,rideDetails)};

        if(ridePhotos.length > 0){
            data.ride.attachments = ridePhotos.map((element:any) => element.originFileObj)
        }

        if(photoPass.length > 0){
            data.ride.photo_pass = photoPass.map((element:any) => element.originFileObj)[0]
        }

        data.ride.optional_params = false;
        
        Object.keys(data.ride).forEach(key => data.ride[key] === undefined && delete data.ride[key])
       
        let formData = convertJSONToFormData(data)

        if(rideId !== 'new') {
            await RideService.updateLiveRide(rideId, formData,
                () => {
                    setRidePhotos([])
                    setPhotoPass([])
                    let URL = generatePath(appRoutes.RIDE_DETAILS, {rideId: rideId})
                    props.history.push(URL)
                },
                () => {},
                () => {},
            )
        }else{
            await RideService.createNewRide(formData,
                (response: { ride: any; }) => {
                    let rideId = response.ride?.id;
                    let URL = generatePath(appRoutes.RIDE_DETAILS, {rideId: rideId})
                    props.history.push(URL)
                },
                () => {},
                () => {},
            )
        }
    };

    const setFormValues = (rideValues: any) => {
        let initialFormValues = {
            name: rideValues?.name,
            shortName: rideValues?.shortName,
            parkId: rideValues?.parkId?.toString(),
            openEmhMorning: rideValues?.openEmhMorning ? 'true' : 'false',
            openEmhEvening: rideValues?.openEmhEvening ? 'true' : 'false',
            singleRider: rideValues?.singleRider ? 'true' : 'false',
            openVeryMerry: rideValues?.openVeryMerry ? 'true' : 'false',
            openNotSoScary: rideValues?.openNotSoScary ? 'true' : 'false',
            duration: rideValues?.duration,
            averageWaitTime: rideValues?.averageWaitTime,
            heightRestriction: rideValues?.heightRestriction,
            whatItIs: rideValues?.whatItIs,
            riderSwap: rideValues?.riderSwap ? 'true' : 'false',
            fastpassOnly: rideValues?.fastpassOnly ? 'true' : 'false',
            whenToGo: rideValues?.whenToGo,
            wet: rideValues?.wet ? 'true' : 'false',
            hiddenMickeyAssociated: rideValues?.hiddenMickeyAssociated ? 'true' : 'false',
            status: rideValues?.status,
            isPhotopassAvailable: rideValues?.isPhotopassAvailable ? 'true' : 'false',
            rideTypeIds: rideValues?.rideTypeMaps?.map((el:any) => el.rideTypeId.toString()),
            themeIds: rideValues?.rideThemeMaps?.map((el:any) => el.themeId.toString()),
            geoCoordinates: (rideValues?.lat ? rideValues.lat : 0) +','+ (rideValues?.long? rideValues.long : 0),
            photopassDescription: rideValues?.photopassDescription || '',
            cubeHouseId: rideValues?.cubeHouseId,
        };
        setInitialValues(initialFormValues)
    }

    const booleanMeta = [
        {value: 'true', label: 'Yes'},
        {value: 'false', label: 'No'},
    ];

    const statusMeta = [
        {value: 'Enable', label: 'Enable'},
        {value: 'Disable', label: 'Disable'},
    ];


    return(
        <div className="component-styles">
            <div className="back-button" onClick={()=>props.history.goBack()}><LeftSquareFilled />Back</div>
            {loading && <CircularLoader/>}
            {!loading && <div className="ride-details">
                <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
                    {({
                          handleChange,
                          values,
                          setFieldValue
                      }) => {
                        return (
                            <Form>
                                {rideId !== 'new' && <div className="ride-form">
                                    <div className="label">Ride ID</div>
                                    <div className="value">{rideId}</div>
                                </div>}

                                <div className="ride-form">
                                    <div className="label">Ride name</div>
                                    <InputField type="text" name="name" placeholder="Ride name"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Short name</div>
                                    <InputField type="text" name="shortName" placeholder="Short name"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Park name</div>
                                    <DropdownField placeholder="Park name" options={parksMeta}
                                                   mode="single" name="parkId" onChange={(value: any) => {
                                        setFieldValue('parkId',value)
                                        handleThemeParkRides(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Updated at</div>
                                    <div className="value">{moment(rideValues?.updatedTime).format('DD/MM/YYYY')}</div>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Morning Magic hours</div>
                                    <DropdownField placeholder="Morning Magic hours" options={booleanMeta}
                                                   mode="single" name="openEmhMorning" onChange={(value: any) => {
                                        handleChange("openEmhMorning")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Evening Magic hours</div>
                                    <DropdownField placeholder="Evening Magic hours" options={booleanMeta}
                                                   mode="single" name="openEmhEvening" onChange={(value: any) => {
                                        handleChange("openEmhEvening")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Single rider</div>
                                    <DropdownField placeholder="Single rider" options={booleanMeta}
                                                   mode="single" name="singleRider" onChange={(value: any) => {
                                        handleChange("singleRider")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Time Zone</div>
                                    <div className="value">{rideValues?.timeZone}</div>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Very Merry Christmas Party</div>
                                    <DropdownField placeholder="Very Merry Christmas Party" options={booleanMeta}
                                                   mode="single" name="openVeryMerry" onChange={(value: any) => {
                                        handleChange("openVeryMerry")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Scary Halloween</div>
                                    <DropdownField placeholder="Scary Halloween" options={booleanMeta}
                                                   mode="single" name="openNotSoScary" onChange={(value: any) => {
                                        handleChange("openNotSoScary")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Ride duration</div>
                                    <InputField type="number" name="duration" placeholder="Ride duration"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Average wait time</div>
                                    <InputField type="number" name="averageWaitTime" placeholder="Average wait time"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Height restriction</div>
                                    <InputField type="number" name="heightRestriction" placeholder="Height restriction"/>
                                </div>

                                <div className="ride-form textarea">
                                    <div className="label">Description</div>
                                    <InputField name="whatItIs" placeholder="Description" type="textarea"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Accessibility info filter</div>
                                    <DropdownField placeholder="Accessibility info filter" options={booleanMeta}
                                                   mode="single" name="accessibilityInfoFilter" onChange={(value: any) => {
                                        handleChange("accessibilityInfoFilter")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Rider swap</div>
                                    <DropdownField placeholder="Rider swap" options={booleanMeta}
                                                   mode="single" name="riderSwap" onChange={(value: any) => {
                                        handleChange("riderSwap")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Crowd expectation</div>
                                    <div className="value">8/10</div>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Fast pass</div>
                                    <DropdownField placeholder="Fast pass" options={booleanMeta}
                                                   mode="single" name="fastpassOnly" onChange={(value: any) => {
                                        handleChange("fastpassOnly")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Best time to go</div>
                                    <InputField name="whenToGo" placeholder="Best time to go" type="text"/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Operator type</div>
                                    <div className="value">{rideValues?.operatorType}</div>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Indoor ride</div>
                                    <DropdownField placeholder="Indoor ride" options={booleanMeta}
                                                   mode="single" name="indoorRide" onChange={(value: any) => {
                                        handleChange("indoorRide")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Wet ride</div>
                                    <DropdownField placeholder="Wet ride" options={booleanMeta}
                                                   mode="single" name="wet" onChange={(value: any) => {
                                        handleChange("wet")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Hidden mickey associated with the ride</div>
                                    <DropdownField placeholder="Hidden mickey" options={booleanMeta}
                                                   mode="single" name="hiddenMickeyAssociated" onChange={(value: any) => {
                                        handleChange("hiddenMickeyAssociated")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Ride status</div>
                                    <DropdownField placeholder="Ride status" options={statusMeta}
                                                   mode="single" name="status" onChange={(value: any) => {
                                        handleChange("status")(value)
                                    }}/>
                                </div>

                                <div className="ride-form">
                                    <div className="label">Photo pass available</div>
                                    <DropdownField placeholder="Photo pass available" options={booleanMeta}
                                                   mode="single" name="isPhotopassAvailable" onChange={(value: any) => {
                                        handleChange("isPhotopassAvailable")(value)
                                    }}/>
                                </div>


                                {values?.isPhotopassAvailable === 'true' &&
                                <>
                                <div>
                                    <div className='label'>Photo pass</div>
                                    <div className='image-upload-container'>
                                        <FileUpload fileList={photoPass} setFileList={setPhotoPass} limit={1}/>
                                    </div>
                                    {rideValues?.photopassThumbUrl &&
                                    <ImageViewer imageUrl={rideValues?.photopassThumbUrl}
                                                 deleteService={RideService.deleteRidePhotopass}
                                                 id={rideId}
                                                 refreshData={fetchRideDetails}
                                    />
                                    }
                                </div>

                                <div className="ride-form textarea">
                                    <div className='label'>Photo pass</div>
                                    <InputField name="photopassDescription" placeholder="Photo pass Description" type="textarea"/>
                                </div>
                                </>
                                }
                                <div>
                                    <div className='label'>
                                        Photos for Ride <i>(Max 5 photos for each ride) Size should be 255*525</i>
                                    </div>
                                    <div className='image-upload-container'>
                                        <FileUpload fileList={ridePhotos} setFileList={setRidePhotos} limit={5}/>
                                    </div>
                                </div>

                                <AttractionImages attractionValues={rideValues}
                                                  labelText={'Photos for ride'}
                                                  refreshAttraction={fetchRideDetails}
                                />

                                <div className="ride-form multi-select">
                                    <div className="label">Ride type</div>
                                    <DropdownField placeholder="Ride type" options={rideTypesMeta}
                                                   mode="multiple" name="rideTypeIds" onChange={(value:any)=>{
                                        setFieldValue('rideTypeIds',value)}
                                    }/>
                                </div>

                                <div className="ride-form multi-select">
                                    <div className="label">Theme</div>
                                    <DropdownField placeholder="Theme" options={themesMeta}
                                                   mode="multiple" name="themeIds" onChange={(value: any) => {
                                        setFieldValue('themeIds',value)
                                    }}/>
                                </div>

                                <div className='ride-location-details'>
                                    <div className='ride-location-details__title'>Associate this ride with Themepark API.</div>
                                    <div className="ride-form">
                                        <div className='label'>Ride</div>
                                        <DropdownField placeholder="Ride name" options={themeParksRideMeta}
                                                       mode="single" name="cubeHouseId" onChange={(value: any) => {
                                            setFieldValue('cubeHouseId',value)
                                        }}/>
                                        <div className='ride-id'>Ride ID: {values?.cubeHouseId}</div>
                                    </div>
                                    <div className="ride-form">
                                        <div className='label'>Geo Coordinates (lat,long)</div>
                                        <InputField name="geoCoordinates" placeholder="Geo coordinates" type="text"/>
                                    </div>
                                </div>

                                <div className="form-actions">
                                    <Button key="back" type="text" onClick={()=>props.history.goBack()}>Cancel</Button>
                                    <Button key="approve" type="primary" htmlType="submit">Save</Button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>}
        </div>
    )
}

export default withRouter(RideForm);
