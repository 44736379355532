import React from "react";
import './games.scss';
import {Tabs} from "antd";
import HiddenMickeyList from "./HiddenMickeyList";
import Trivia from "./Trivia";
import Jokes from "./Jokes";

const { TabPane } = Tabs;

const Games = () => {

    return (
        <div className="component-styles">
            <Tabs defaultActiveKey="1" className="games-tabs">
                <TabPane tab="HIDDEN MICKEY" key="1" className="">
                    <HiddenMickeyList />
                </TabPane>
                <TabPane tab="TRIVIA" key="2" className="">
                    <Trivia />
                </TabPane>
                <TabPane tab="JOKES" key="3" className="">
                    <Jokes />
                </TabPane>
            </Tabs>
        </div>
    )
}

export default Games;
