import React, {useEffect, useState} from "react";
import './eventDates.scss';
import {Button} from "antd";
import {LeftSquareFilled,CloseCircleFilled} from "@ant-design/icons/lib";
import {DeleteConfirmation} from "../../../shared/components/DeleteConfirmation";
import * as appRoutes from "../../../routes/routeConstants/appRoutes";
import ExtraMagicHoursService from "../../../services/ExtraMagicHoursService/extraMagicHours.service";
import {serialize} from "serializr";
import {LocationEventDatesModel} from "../../../models/extraMagicHours.model";
import CircularLoader from "../../../shared/components/CircularLoader";
import EventCreateModal from "../EventCreateModal";

const EventDatesManagement = (props: any) => {

    useEffect(()=>{
        getLocationEventDates('1',setLocation1Data);
        getLocationEventDates('2',setLocation2Data);
    },[])

    const event = props.location.state

    const [location1Data,setLocation1Data] = useState<any>([]);
    const [location2Data,setLocation2Data] = useState<any>([]);
    const [deleteVisible,setDeleteVisible]=useState(false);
    const [visible,setVisible] = useState(false);
    const [index,setIndex] = useState(-1);
    const [locationId,setLocationId] = useState<any>('');
    const [loading,setLoading] = useState<boolean>(false);

    const getLocationEventDates = (locationId: string, setState: Function) => {
        setLoading(true)
        ExtraMagicHoursService.fetchEventDates(locationId,
            (response: any)=>{
                if(event === 'christmas') {
                    setState(response?.christmasDates?.sort() || [])
                }else{
                    setState(response?.halloweenDates?.sort() || [])
                }
            },
            ()=>{},
            ()=>{
                setLoading(false)
            },
        ).then()
    }

    const onSubmit = (values: any,resetForm: any) =>{
        let locationId = values?.location;
        let existingDates = locationId === "1" ? location1Data : location2Data;
        existingDates.push(values.date);
        updateEvents(existingDates,locationId);
    }

    const handleDelete = (locationId:string, index: number) =>{
        setIndex(index);
        setLocationId(locationId);
        setDeleteVisible(true);
    };

    const removeDate = () =>{
        let datesData = locationId === '1' ? location1Data :location2Data;
        datesData.splice(index,1);
        updateEvents(datesData, locationId)
    };

    const updateEvents = (dates: any,id: string) =>{
        let keyName = event === 'christmas' ? 'christmasDates' : 'halloweenDates';
        let value = {[keyName]:dates};
        let data = {location: serialize(LocationEventDatesModel,value)};

        ExtraMagicHoursService.updateEventDates(id,data,
            ()=>{
                getLocationEventDates(id, id === '1' ?
                    setLocation1Data : setLocation2Data);
                setVisible(false);
                setDeleteVisible(false);
                setLocationId('');
            },
            ()=>{},
            ()=>{},
        ).then()
    }

    return (
        <div className="component-styles">
            <DeleteConfirmation deleteVisible={deleteVisible}
                                setDeleteVisible={setDeleteVisible}
                                text='date'
                                handleDelete={removeDate}/>
            <div className='back-button' onClick={()=>
                props.history.push(appRoutes.EXTRA_MAGIC_HOURS)}>
                <LeftSquareFilled />Back
            </div>
            {loading && <CircularLoader/>}
            {!loading && <>
                <div className='title'>
                    {event === 'christmas' ? 'Very merry christmas' : 'Not so scary halloween'} Event this year on
                </div>
                <div className='page-actions'>
                    <Button type='primary' key='add' className="add-new-ride"
                     onClick={()=>setVisible(true)}>Add</Button>
                </div>
                <div className='event-dates'>
                    <div className=''>Disney World</div>
                    {location1Data?.map((date:string,i:number) => <div className='event-dates__display'>{date}
                       <CloseCircleFilled onClick={()=>handleDelete('1',i)}/></div>)}
                </div>
                <div className='event-dates'>
                    <div className=''>Disney Land</div>
                    {location2Data?.map((date:string,i:number) => <div className='event-dates__display'>{date}
                       <CloseCircleFilled onClick={()=>handleDelete('2',i)}/></div>)}
                </div>
            </>}
            <EventCreateModal visible={visible}
                              setVisible={setVisible}
                              onSubmit={onSubmit}
                          />
        </div>
    )
}

export default EventDatesManagement;
