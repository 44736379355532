import React, {useEffect, useRef, useState} from "react";
import {Form, Formik} from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import {Button, Modal} from "antd";
import {fetchMetaValues} from "../../../shared/utils/fetchMeta";
import {serialize} from "serializr";
import {RideDetailsModel} from "../../../models/ride.model";
import RideService from "../../../services/RideService/ride.service";

const RideApprovalModal = (props:any) => {
    const {setVisible,visible,existingRideId,parkId,rideId,rideName,
        optionalParams,handleSubmitSuccess} = props;

    const formikRef = useRef<any>()

    const formInitState = {
        cubeHouseId: [],
        geoCoordinates:"",
        rideTypeIds:[],
        themeIds:[]
    }

    const [themesMeta, setThemesMeta] = useState([]);
    const [rideTypesMeta, setRideTypesMeta] = useState([]);
    const [rideOptions, setRideOptions] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(()=>{
        fetchMetaValues('themes',setThemesMeta);
        fetchMetaValues('ride_types',setRideTypesMeta);
    },[])

    useEffect(()=>{
        if(parkId) {
            getThemeParkRides()
        }
    },[parkId])

    const getThemeParkRides = () => {
        RideService.fetchThemeParkRides(parkId,
            (rideOptions:any)=>{
                setRideOptions(
                    rideOptions.map((obj: { name: any; id: any; }) => {
                        return {
                            value:obj.id.toString(),
                            label:obj.name
                        }
                    })
                )
            },
            ()=>{},
            ()=>{},
        ).then()
    }

    const onSubmit = (rideDetails: any,resetForm: Function) =>{
        setLoading(true)
        if(rideDetails.geoCoordinates){
            rideDetails.lat = rideDetails.geoCoordinates.split(',')[0];
            rideDetails.long = rideDetails.geoCoordinates.split(',')[1];
        }
        let data = {ride: serialize(RideDetailsModel,rideDetails)}
        if(!existingRideId && Object.keys(optionalParams).length === 0) {
            data.ride.external_api_ride_id = rideId;
            data.ride.optional_params = false;
        }else{
            data.ride = {
                ...data.ride,
                ...optionalParams
            }
            data.ride.park_id=parkId;
            data.ride.optional_params = true;
        }
        //to remove undefined key value pairs
        Object.keys(data.ride).forEach(key => data.ride[key] === undefined && delete data.ride[key])
        RideService.createNewRide(data,
            () => {
                handleSubmitSuccess()
                resetForm(formInitState);
            },
            () => {},
            () => {
                setLoading(false)
            },
        ).then()
    };


    return(
        <Modal
            title={
                <>
                    <div className="ride-title">{rideName}</div>
                    <div className="modal-tag">Associate this ride with theme park API</div>
                </>
            }
            centered
            onCancel={()=>{setVisible(false);formikRef.current.resetForm(formInitState)}}
            visible={visible}
            footer={null}
        >
            <Formik innerRef={formikRef}
                    initialValues={formInitState}
                    onSubmit={(values,{resetForm})=> onSubmit(values,resetForm)}
                    enableReinitialize>
                {({
                      handleChange,
                      values,
                      setFieldValue
                  }) => {
                    return (
                        <Form>
                            <div className="ride-form">
                                <div className="label">Select Ride</div>
                                <DropdownField placeholder="Select ride" options={rideOptions}
                                               mode="single" name="cubeHouseId" onChange={(value: any) => {
                                    handleChange("cubeHouseId")(value)
                                }}/>
                            </div>
                            <div className="ride-form">
                                <div className="label">Geo Coordinates (lat,long)</div>
                                <InputField type="text" name="geoCoordinates" placeholder="Enter geo coordinates"/>
                            </div>

                            <div className="ride-form">
                                <div className="label">Ride type</div>
                                <DropdownField placeholder="Ride type" options={rideTypesMeta}
                                               mode="multiple" name="rideTypeIds" onChange={(value:any)=>{
                                    setFieldValue('rideTypeIds',value)}
                                }/>
                            </div>
                            <div className="ride-form">
                                <div className="label">Theme type</div>
                                <DropdownField placeholder="Themes" options={themesMeta}
                                               mode="multiple" name="themeIds" onChange={(value:any)=>{
                                    setFieldValue('themeIds',value)}
                                }/>
                            </div>
                            <div className="form-actions">
                                <Button key="back" type="text" onClick={() =>{setVisible(false);
                                    formikRef.current.resetForm(formInitState)}}>Cancel</Button>
                                <Button key="approve" type="primary" htmlType="submit"
                                    loading={loading}>Approve</Button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Modal>
    )
}

export default RideApprovalModal;
